import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EncrDecrService } from 'src/app/services/EncrDecrService';
import { HealthservicesService } from 'src/app/services/healthservices.service';
import { AppSettings } from '../globals';
// import { User } from '../Reusable/Modal';

@Component({
  selector: 'app-my-quotation',
  templateUrl: './my-quotation.component.html',
  styleUrls: ['./my-quotation.component.css']
})


export class MyQuotationComponent implements OnInit, OnDestroy {

  UserId: string;
  tab_1: boolean = true;
  tab_2: boolean = false;
  QuotesList = []; 
  QuotesListMotor = [];
  vehicleTypes: number[] = [];
  dtTrigger: Subject<any> = new Subject();
  dtTriggerMotor: Subject<any> = new Subject();
  dtOptionhlth: DataTables.Settings = {};
  dtOptionsMotor: DataTables.Settings = {};

  constructor(
    private healthMasterService: HealthservicesService,
    private router: Router,
    private globalService: GlobalService,
    private authService: AuthenticationService,
    private EncrDecr: EncrDecrService
  ) {
    if (sessionStorage.getItem('MobileNo') != null) {
      this.globalService.lendingpage = true;
      this.UserId = sessionStorage.getItem('MobileNo');
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.dtOptionhlth = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: [7, 'desc']
    };
    this.dtOptionsMotor = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true,
      order: [7, 'desc']
    };
    
    this.activate_tab1(); 
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.dtTriggerMotor.unsubscribe();
  }

  GetHealthFeaturesList() {
    debugger
    $('#gridHealth').DataTable().destroy(); 
    this.healthMasterService.GetQuotesList(this.UserId).subscribe((data: any[]) => {
      this.QuotesList = data;
      this.dtTrigger.next(); 
    });
  }

  GetMotorFeaturesList() {
    debugger
    $('#gridMotor').DataTable().destroy(); 
    this.healthMasterService.GetQuotesListMotor(this.UserId).subscribe((data: any[]) => {
      this.QuotesListMotor = data;
      console.log(this.QuotesListMotor);
      this.vehicleTypes = data.map(item => item.VehicleType);
      this.dtTriggerMotor.next(); 
    });
  }

  MotorbuttonInRowClick(SessionId: string, PlanTypeId: string, VehicleType: number) {
    debugger;
    const queryParams = { sessionId: SessionId, planId: PlanTypeId };

    if (VehicleType == 3) {
        this.router.navigate(['/twowheeler-proposal'], { queryParams: queryParams });
    } else {
        this.router.navigate(['/car-proposal'], { queryParams: queryParams });
    }
}

  buttonInRowClick(id: string, QuotionId: number) {
    localStorage.setItem('quotationId', QuotionId.toString());
    this.router.navigate(['/healthproposal'], { queryParams: { id: id } });
  }

 




  activate_tab1() {
    document.getElementById("Health").classList.add("ui-tabs-active");
    document.getElementById("Motor").classList.remove("ui-tabs-active");
    this.tab_1 = true;
    this.tab_2 = false;
    this.GetHealthFeaturesList(); 
  }

  activate_tab2() {
    document.getElementById("Motor").classList.add("ui-tabs-active");
    document.getElementById("Health").classList.remove("ui-tabs-active");
    this.tab_1 = false;
    this.tab_2 = true;
    this.GetMotorFeaturesList(); 
  }
}
