import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import { MasterService } from 'src/app/services/master.service';
import { MotorService } from 'src/app/services/motor.service';
import { twoWheelerDetails, QuotationModel } from '../../common/Models/twoWheeler';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/global.service';
declare var $: any;
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { EncrDecrService } from 'src/app/services/EncrDecrService';
import { User, UserAuthentication } from 'src/app/common/Models/userDetail';
import { token } from 'src/app/common/Models/token';

@Component({
  selector: 'app-car-insurance',
  templateUrl: './car-insurance.component.html',
  styleUrls: ['./car-insurance.component.css']
})
export class CarInsuranceComponent implements OnInit {

  public customPatterns = { 'A': { pattern: new RegExp('/^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/') } };
  selectBike: string = '';
  isHaveBikeNumber: boolean = false;
  ErrorHandle: ErrorHandle;
  divconstant: boolean = true;
  divRegistration: boolean = false;
  divManufacturer: boolean = true;
  divVarient: boolean = false;
  divModelVarient: boolean = false;
  divRegistrationYear: boolean = false;
  divPersonal: boolean = false;
  divPolicyType: boolean = false;
  divWhichPolicyType: boolean = true;
  divExpiredPolicy: boolean = false;
  divCarNo: boolean = false;
  divOTP: boolean = true;
  @ViewChild("registrationNumber", { static: false }) registrationNumber: ElementRef;
  vehicleDetails: QuotationModel;
  carvehicleDetails: twoWheelerDetails = new twoWheelerDetails();
  prevvehicleDetails: twoWheelerDetails = new twoWheelerDetails();
  ManufacturerList = [];
  ModelsList = [];
  VariantList = [];
  RegistrationCode = [];
  RegistrationYearList = [];
  startPage: any;
  paginationLimit: any;
  isback: string;
  data = [];
  dataManufacturer = [];
  dataVarient = [];
  PopularCityOfRegistration = [];
  OrgCityOfRegistration = [];
  showBox = true;
  private searchTerms = new Subject<string>();
  public pipe = new DatePipe('en-US');
  token: token;
  isLogedUser: boolean = false;
  verifyotp: boolean = false;
  isMobileOTPVerified: boolean = false;
  isEmailOTPVerified: boolean = false;
  newRegNumber: string;
  constructor(private router: Router, private routerActive: ActivatedRoute, private masterService: MasterService
    , private activeRouter: ActivatedRoute, private globalService: GlobalService, private motorService: MotorService) { }

  ngOnInit() {
    debugger;

    sessionStorage.setItem('vehicleEngineno', "");
    sessionStorage.setItem('vehicleChassino', "");
    this.paginationLimit = 10;
    $("#haveBikeNumber1").click(function () {
      $(this).toggleClass("active");
    });
    this.registrationNoMasking();
    this.GetPopularCityOfRegistration();
    // let VehicleType = 5;
    this.isback = this.activeRouter.snapshot.queryParamMap.get('isback');

    sessionStorage.setItem('vehicleEngineno', "");
    sessionStorage.setItem('vehicleChassino', "");

  }

  HaveBikeNumber(bikeType: any) {
    debugger;
    this.selectBike = bikeType;
    if (this.selectBike == "NEW" || this.selectBike == "NO") {
      sessionStorage.removeItem('registrationNumber');
      sessionStorage.removeItem('vehicledata');
      if (this.selectBike == "NO") this.selectBike = "ROLLOVER"
      this.router.navigateByUrl('/car-rto?type=' + this.selectBike);
      // $('#withoutModal').modal('toggle');
      // $("#withoutModal").before("<div class='modal-show'></div>");
      //   $("#withoutModal").css("display", "flex");
      //   this.goDivManufacturer('');
    }
  }

  CloseModal() {
    $('#withoutModal').hide();
    $('.modal-show').hide();
  }

  GetPopularCityOfRegistration() {
    debugger;
    this.divRegistration = true;
    this.masterService.GetPopularCityOfRegistration().subscribe((data: any[]) => {
      this.OrgCityOfRegistration = data;
      console.log(this.OrgCityOfRegistration);
      this.PopularCityOfRegistration = data.filter((v, i, a) => a.findIndex(t => (t.CityId === v.CityId)) === i);
      console.log(this.PopularCityOfRegistration);
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Please contact administrator.', '', 'error');
      }
    }));
  }

  filterList(CityId) {
    return this.RegistrationCode = this.OrgCityOfRegistration.filter(x => x.CityId == CityId);
  }

  searchClient(term): void {
    let searchText = term.target.value;//(<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.data = [];

    if (searchText.length > 1) {
      this.masterService.GetUnPopularCityOfRegistration(searchText).subscribe((vdata: any[]) => {
        let getdata = [];

        for (let i = 0; i < vdata.length; i++) {
          getdata.push({ id: vdata[i].CityId, name: vdata[i].CityName + ' (' + vdata[i].RegistrationCode + ')', address: vdata[i].CityName + ' ' + vdata[i].RegistrationCode });
          console.log(vdata[i].RegistrationCode);
        }
        this.data = getdata;

      })
    }
    this.searchTerms.next(term);
  }

  onFocused(term) {
    let searchText = term.target.value;//(<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.data = [];
    if (searchText == "") searchText = "delhi";
    this.masterService.GetUnPopularCityOfRegistration(searchText).subscribe((vdata: any[]) => {
      this.data = vdata;
    })

  }

  othersManufacturer() {
    this.paginationLimit = Number(this.paginationLimit) + 100;
  }

  goDivManufacturer(regCode) {
    this.paginationLimit = 10;
    this.divRegistration = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divRegistrationYear = false;
    this.divExpiredPolicy = false;
    this.divManufacturer = true;
    this.data = [];
    // if (regCode != "") {
    let VehicleType = 5
    //   this.carvehicleDetails.RegistrationNo = regCode.toUpperCase();
    //   this.carvehicleDetails.RegistrationCode = regCode.toUpperCase();
    //   sessionStorage.setItem('registrationNumber', regCode.toUpperCase());
    //   this.getCity(regCode);
    this.masterService.GetPopularManufacturer(VehicleType).subscribe((data: any[]) => {
      this.ManufacturerList = data;
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Please contact administrator.', '', 'error');
      }
    }));
    // }
    this.searchClientManufacture('');
  }

  searchClientManufacture(term): void {
    this.dataManufacturer = [];
    // let VehicleType=3//Two Wheeler
    let VehicleType = 5//Pvt Car      
    this.masterService.GetManufacturer(VehicleType).subscribe((data: any[]) => {
      let getdata = [];
      for (let i = 0; i < data.length; i++) {
        getdata.push({ id: data[i].ManufacturerId, name: data[i].ManufacturerName + ' (' + data[i].ManufacturerId + ')', address: data[i].ManufacturerName });
        console.log(data[i].ManufacturerName);
      }
      this.dataManufacturer = getdata;

    });
  }

  onClickedOutside(e: Event) {
    debugger;
    this.showBox = false;
  }

  goDivVarient(ManufacturerId, ManufacturerName) {
    debugger;
    if (ManufacturerId != "") {
      this.carvehicleDetails.ManufacturerId = ManufacturerId;
      this.carvehicleDetails.ManufacturerName = ManufacturerName;
      if (this.isback != 'Yes') {
        this.carvehicleDetails.ModelId = 0;
        this.carvehicleDetails.ModelName = "";
        this.carvehicleDetails.VariantId = 0;
        this.carvehicleDetails.VariantName = "";
        this.VariantList = [];
      }
      else {
        $('.first').addClass("in");
      }
    }
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divRegistrationYear = false;
    this.divExpiredPolicy = false;
    this.divVarient = false;
    this.divModelVarient = true;

    this.masterService.GetPopularVehicleModels(this.carvehicleDetails.ManufacturerId).subscribe((data: any[]) => {
      this.ModelsList = data;
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Please contact administrator.', '', 'error');
      }
    }));
    this.searchClientVarient('');
  }

  searchClientVarient(term): void {
    this.dataVarient = [];
    this.masterService.GetPopularVehicleModels(this.carvehicleDetails.ManufacturerId).subscribe((data: any[]) => {
      let getdata = [];
      for (let i = 0; i < data.length; i++) {
        getdata.push({ id: data[i].ModelId, name: data[i].ModelName + ' (' + data[i].ModelId + ')', address: data[i].ModelName });
        console.log(data[i].ManufacturerName);
      }
      this.dataVarient = getdata;
    });
  }

  govariantList(ModelId, ModelName) {
    if (ModelId != '') {
      this.carvehicleDetails.ModelId = ModelId;
      this.carvehicleDetails.ModelName = ModelName;
    }
    this.divVarient = true;
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divRegistrationYear = false;
    this.divExpiredPolicy = false;
    this.divModelVarient = false;


    this.masterService.GetVariant(this.carvehicleDetails.ModelId).subscribe((data: any[]) => {
      this.VariantList = data;
      console.log(this.VariantList);
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Please contact administrator.', '', 'error');
      }
    }));
  }

  goRegistrationCode(CityId) {
    $("#div_" + sessionStorage.getItem('RegCode')).children(".rto_list_code").hide();
    $("#div_" + CityId).children(".rto_list_code").toggle();

    sessionStorage.removeItem('RegCode');
    sessionStorage.setItem('RegCode', CityId);
  }

  goDivRTO() {
    this.divRegistration = true;
    this.divManufacturer = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divExpiredPolicy = false;
    this.divRegistrationYear = false;
  }

  goDivPersonal(regCode) {
    this.divPersonal = true;
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divExpiredPolicy = false;
    this.divRegistrationYear = false;
    if (regCode != "") {
      // let VehicleType = 5
      this.carvehicleDetails.RegistrationNo = regCode.toUpperCase();
      this.carvehicleDetails.RegistrationCode = regCode.toUpperCase();
      sessionStorage.setItem('registrationNumber', regCode.toUpperCase());
      this.getCity(regCode);
    }
  }

  goDivRegistrationYear(Variant) {
    debugger;
    if (this.isback != 'Yes') {
      this.carvehicleDetails.VariantId = Variant.VehicleVariantId;
      this.carvehicleDetails.VariantName = Variant.VariantName;
      this.carvehicleDetails.SeatingCapacity = Variant.SeatingCapacity;
      this.carvehicleDetails.CubicCapacity = Variant.CubicCapacity;
      this.carvehicleDetails.FuelType = Variant.FuelType;
    }

    this.RegistrationYearList = [];
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divExpiredPolicy = false;

    let currentyear = new Date().getFullYear();
    let i = currentyear - 15;

    for (i; i < currentyear; i++) {
      this.RegistrationYearList.push({ year: i });
    }
    this.RegistrationYearList.push({ year: currentyear });
    //console.log(this.RegistrationYearList);
    //this.RegistrationYearList.sort((a,b) =>  a < b ? 1 : a > b ? -1 : 0)

    this.RegistrationYearList.sort(function (a, b) { return b.year - a.year });

    console.log(this.RegistrationYearList);
    let id = this.activeRouter.snapshot.queryParamMap.get('type');
    if (id == "NEW") {
      this.goDivExpiredPolicy(currentyear);
    } else {
      this.divconstant = true;
      this.divRegistrationYear = true;
    }
  }

  goDivExpiredPolicy(regYear) {
    debugger;
    this.divconstant = false;
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divRegistrationYear = false;
    this.divExpiredPolicy = true;
    let rgdate = new Date();
    if (regYear != null) {
      if (regYear != undefined) {
        if (regYear.toString().includes("/")) {
          let dateParts = regYear.split("/");
          // month is 0-based, that's why we need dataParts[1] - 1
          let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
          rgdate = new Date(dateObject);
        }
        else {
          rgdate = new Date(regYear);
        }
      }
    }
    let year = rgdate.getFullYear();
    let month = rgdate.getMonth() + 1;
    let day = rgdate.getDay();

    this.carvehicleDetails.ManufacturerYear = year.toString();
    // var d = new Date();
    // var n = d.getMonth()+1;
    debugger
    //let pipe = new DatePipe('en-US');
    this.carvehicleDetails.RegistationDate = this.pipe.transform(rgdate, 'yyyy-MM-dd');
    // $('#myModal').show();
    //  if(this.isback!='Yes') 
    //   {
    this.saveRTOQuote();
    // }
  }

  saveRTOQuote() {
    let res = sessionStorage.getItem('vehicledata');
    let id = this.activeRouter.snapshot.queryParamMap.get('type');
    if (res == null) {
      sessionStorage.setItem('vehicledata', JSON.stringify(this.carvehicleDetails));
    }
    else {

      this.prevvehicleDetails = JSON.parse(sessionStorage.getItem('vehicledata'));
      if (this.carvehicleDetails.VariantId == undefined) {
        this.carvehicleDetails.VariantId = this.prevvehicleDetails.VariantId;
        this.carvehicleDetails.VariantName = this.prevvehicleDetails.VariantName;
        this.carvehicleDetails.SeatingCapacity = this.prevvehicleDetails.SeatingCapacity;
        this.carvehicleDetails.CubicCapacity = this.prevvehicleDetails.CubicCapacity;
      }
      else if (this.prevvehicleDetails.VariantId != this.carvehicleDetails.VariantId) {
        sessionStorage.setItem('vehicledata', JSON.stringify(this.carvehicleDetails));
      }
      if (this.carvehicleDetails.ModelId == undefined) {
        this.carvehicleDetails.ModelId = this.prevvehicleDetails.ModelId;
      }
      else if (this.prevvehicleDetails.ModelId != this.carvehicleDetails.ModelId) {
        sessionStorage.setItem('vehicledata', JSON.stringify(this.carvehicleDetails));
      }
      if (this.carvehicleDetails.ManufacturerYear == undefined) {
        this.carvehicleDetails.ManufacturerYear = this.prevvehicleDetails.ManufacturerYear;
      }
      else if (this.prevvehicleDetails.ManufacturerYear != this.carvehicleDetails.ManufacturerYear) {
        sessionStorage.setItem('vehicledata', JSON.stringify(this.carvehicleDetails));
      }
      if (this.carvehicleDetails.ManufacturerId == undefined) {
        this.carvehicleDetails.ManufacturerId = this.prevvehicleDetails.ManufacturerId;
      }
      else if (this.prevvehicleDetails.ManufacturerId != this.carvehicleDetails.ManufacturerId) {
        sessionStorage.setItem('vehicledata', JSON.stringify(this.carvehicleDetails));
      }
      if (this.carvehicleDetails.RegistrationNo == undefined) {
        this.carvehicleDetails.RegistrationNo = this.prevvehicleDetails.RegistrationNo;
      }
      else if (this.prevvehicleDetails.RegistrationNo != this.carvehicleDetails.RegistrationNo) {
        sessionStorage.setItem('vehicledata', JSON.stringify(this.carvehicleDetails));
      }
    }
    //sessionStorage.setItem('vehicledata',JSON.stringify(this.vehicleDetails));

    debugger
    const quoteModel = new QuotationModel();
    quoteModel.UserId = '';
    quoteModel.ManufacturerId = this.carvehicleDetails.ManufacturerId;
    quoteModel.ModelId = this.carvehicleDetails.ModelId;
    quoteModel.VariantId = this.carvehicleDetails.VariantId;
    quoteModel.SeatingCapacity = this.carvehicleDetails.SeatingCapacity;
    quoteModel.CubicCapacity = this.carvehicleDetails.CubicCapacity;
    quoteModel.FuelType = this.carvehicleDetails.FuelType;
    //  quoteModel.VehicleType=3;//Two Wheeler
    quoteModel.VehicleType = 5;//Pvt Car
    quoteModel.BuyerState = this.carvehicleDetails.RegistrationNo.substr(0, 2);
    if (id == 'NEW') {
      quoteModel.ManufacturerYear = (new Date()).getFullYear().toString();
    }
    else {
      quoteModel.ManufacturerYear = this.carvehicleDetails.ManufacturerYear;
    }
    quoteModel.DateOfRegistration = this.carvehicleDetails.RegistationDate;
    quoteModel.RegistrationCode = this.carvehicleDetails.RegistrationNo;
    if (id == 'NEW') {
      quoteModel.DayOfPurchase = this.pipe.transform(new Date(), 'yyyy-MM-dd').split('-')[2];
      quoteModel.MonthOfPurchase = this.pipe.transform(new Date(), 'yyyy-MM-dd').split('-')[1];
      quoteModel.YearOfPurchase = this.pipe.transform(new Date(), 'yyyy-MM-dd').split('-')[0];
    }
    else {
      quoteModel.DayOfPurchase = this.carvehicleDetails.RegistationDate.split('-')[2];
      quoteModel.MonthOfPurchase = this.carvehicleDetails.RegistationDate.split('-')[1];
      quoteModel.YearOfPurchase = this.carvehicleDetails.RegistationDate.split('-')[0];
    }

    if (id == 'NEW')
      quoteModel.CurrentPolicyType = 'NEW';
    else
      quoteModel.CurrentPolicyType = 'RollOver';
    //quoteModel.PolicyType=this.vehicleDetails.PolicyType;
    quoteModel.PolicyType = "Comprehensive";
    quoteModel.PreviousPolicyType = "Comprehensive";//this.vehicleDetails.PolicyType; 
    if (quoteModel.RegistrationCode != '' && sessionStorage.getItem('RegCode') == '') {
      this.getCity(quoteModel.RegistrationCode);
    }
    debugger
    quoteModel.CityOfRegistration = sessionStorage.getItem('RegCode');
    quoteModel.ClaimMade = "Yes";
    quoteModel.AutomobileMembership = "No";
    quoteModel.LastNCB = null;
    quoteModel.PACoverOwner = "No";
    quoteModel.OwnedBy = "Individual";
    quoteModel.AntiTheftDevices = "No";
    if (quoteModel.FuelType == "CNG" || quoteModel.FuelType == "LPG") {
      quoteModel.CNGKit = "Company Fitted";
    }
    else {
      quoteModel.CNGKit = "Not Fitted";
    }

    quoteModel.GeoGraphicalExtension = "No";
    quoteModel.PaidDriver = "No";
    quoteModel.RestrictTPPDLiability = "No";
    quoteModel.PACover = "No";
    quoteModel.PreviousPolicyExpirDate = this.carvehicleDetails.PolicyExpiryDate;
    this.motorService.postQuoteData(quoteModel).subscribe((result: any) => {
      if (result && result.status == "done")
        sessionStorage.setItem("sessionId", result.Id);

      this.router.navigate(['/car-coverage'], { queryParams: { sessionId: result.Id } })
      // this.router.navigateByUrl('/two-wheeler-quotation?sessionId='+result.Id);
    })

  }

  formatRegNumber(event: Event): void {
    debugger
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value.trim();

    if (/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{2}[0-9]{4}$/.test(value)) {
      let formatted = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
      formatted = formatted.replace(/(.{2})(.{2})(.{2})(.*)/, "$1-$2-$3-$4");
      inputElement.value = formatted;
      this.newRegNumber = formatted;
    }
  }

  getRtoDetails() {

    debugger;
    // let registrationNumber = this.registrationNumber.nativeElement.value.toUpperCase();
    let registrationNumber = this.newRegNumber;
    if (registrationNumber == "") {
      Swal.fire('Please enter registration number', '', 'error');
      return false;
    }
    if (!this.validateRegistrationNo(registrationNumber)) {
      Swal.fire('Please enter valid registration number', '', 'error');
      return false;
    }
    let regNo = registrationNumber;


    this.motorService.getVehicleByRegNo(regNo.replace(' ', '')).subscribe((data: QuotationModel) => {
      debugger;
      if (data) {

        sessionStorage.setItem('registrationNumber', registrationNumber != "" ? registrationNumber : "");
        sessionStorage.setItem('vehicledata', JSON.stringify(data));
        this.vehicleDetails = data;

        this.vehicleDetails.RegistrationCode = registrationNumber.toUpperCase().substring(0, 5);

        this.masterService.saveVehicle(data).subscribe(res => {

          this.saveQuote();
        })

      }
      else {
        debugger;
        this.motorService.getflaVehicleByRegNo(regNo.replace(' ', '')).subscribe((fladata: QuotationModel) => {
          if (fladata) {
            sessionStorage.setItem('registrationNumber', registrationNumber != "" ? registrationNumber : "");
            sessionStorage.setItem('vehicledata', JSON.stringify(fladata));
            this.vehicleDetails = fladata;
            sessionStorage.setItem('vehicleEngineno', fladata.vehicleEngineno != "" ? fladata.vehicleEngineno : "");
            sessionStorage.setItem('vehicleChassino', fladata.vehicleChassino != "" ? fladata.vehicleChassino : "");
            this.vehicleDetails.RegistrationCode = registrationNumber.toUpperCase().substring(0, 5);
            this.vehicleDetails.YearOfPurchase = fladata.YearOfPurchase;
            this.vehicleDetails.MonthOfPurchase = fladata.MonthOfPurchase;
            this.vehicleDetails.DayOfPurchase = fladata.DayOfPurchase;
            this.vehicleDetails.DateOfRegistration = fladata.DateOfRegistration;
            debugger
            if (fladata.fla_vh_class_desc) {
              if (fladata.fla_vh_class_desc.toUpperCase() == "2W") {
                Swal.fire('This vehicle number does belongs to Two Wheeler vehicle.', '', 'error');
                this.router.navigateByUrl('/');
                return false;
              }
            }
            this.masterService.saveVehicle(fladata).subscribe(res => {

              this.saveQuote();
            })
          }
          else {
            this.vehicleDetails.RegistrationCode = registrationNumber.substring(0, 5);
            // this.vehicleDetails.RegistrationCode=registrationNumber;
            Swal.fire('Please enter your vehicle details manually', '', 'error');

            this.router.navigateByUrl('/car-rto?type=ROLLOVER');
          }
        }, ((err: ErrorHandle) => {
          this.ErrorHandle = err;
          // if (err.status == 404) {
          sessionStorage.setItem('registrationNumber', regNo != "" ? regNo : "");
          let vehicledata = new twoWheelerDetails();
          vehicledata.RegistrationNo = regNo;
          vehicledata.RegistrationCode = regNo.substring(0, 5);
          // vehicledata.RegistrationCode=regNo;
          sessionStorage.setItem('vehicledata', JSON.stringify(vehicledata));
          Swal.fire('Please enter your vehicle details manually', '', 'error');
          this.router.navigateByUrl('/car-rto?type=ROLLOVER');
          // }
          if (this.ErrorHandle.statusText == "Bad Request") {
            Swal.fire('Userid and password are incorrect.', '', 'error');
          }
        }));

      }
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (err.status == 404) {
        sessionStorage.setItem('registrationNumber', regNo != "" ? regNo : "");
        let vehicledata = new twoWheelerDetails();
        vehicledata.RegistrationNo = regNo;
        vehicledata.RegistrationCode = regNo.substring(0, 5);
        // vehicledata.RegistrationCode=regNo;
        sessionStorage.setItem('vehicledata', JSON.stringify(vehicledata));
        // this.router.navigateByUrl('/car-rto?type=ROLLOVER');
      }
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Userid and password are incorrect.', '', 'error');
      }
    }));

    this.motorService.getflaVehicleByRegNo(regNo.replace(' ', '')).subscribe((fladata: QuotationModel) => {
      debugger;
      if (fladata) {
        sessionStorage.setItem('registrationNumber', registrationNumber != "" ? registrationNumber : "");
        sessionStorage.setItem('vehicledata', JSON.stringify(fladata));
        this.vehicleDetails = fladata;
        sessionStorage.setItem('vehicleEngineno', fladata.vehicleEngineno != "" ? fladata.vehicleEngineno : "");
        sessionStorage.setItem('vehicleChassino', fladata.vehicleChassino != "" ? fladata.vehicleChassino : "");
        this.vehicleDetails.RegistrationCode = registrationNumber.toUpperCase().substring(0, 5);
        this.vehicleDetails.YearOfPurchase = fladata.YearOfPurchase;
        this.vehicleDetails.MonthOfPurchase = fladata.MonthOfPurchase;
        this.vehicleDetails.DayOfPurchase = fladata.DayOfPurchase;
        this.vehicleDetails.DateOfRegistration = fladata.DateOfRegistration;
        debugger
        if (fladata.fla_vh_class_desc) {
          if (fladata.fla_vh_class_desc.toUpperCase() == "2W") {
            Swal.fire('This vehicle number does belongs to Two Wheeler vehicle.', '', 'error');
            this.router.navigateByUrl('/');
            return false;
          }
        }
        this.masterService.saveVehicle(fladata).subscribe(res => {

          this.saveQuote();
        })
      }
      else {
        this.vehicleDetails.RegistrationCode = registrationNumber.substring(0, 5);
        // this.vehicleDetails.RegistrationCode=registrationNumber;
        Swal.fire('Please enter your vehicle details manually', '', 'error');
        this.router.navigateByUrl('/car-rto?type=ROLLOVER');
      }
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      // if (err.status == 404) {
      sessionStorage.setItem('registrationNumber', regNo != "" ? regNo : "");
      let vehicledata = new twoWheelerDetails();
      vehicledata.RegistrationNo = regNo;
      vehicledata.RegistrationCode = regNo.substring(0, 5);
      // vehicledata.RegistrationCode=regNo;
      sessionStorage.setItem('vehicledata', JSON.stringify(vehicledata));
      Swal.fire('Please enter your vehicle details manually', '', 'error');
      this.router.navigateByUrl('/car-rto?type=ROLLOVER');
      // }
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Userid and password are incorrect.', '', 'error');
      }
    }));

  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  saveQuote() {

    sessionStorage.setItem('vehicledata', JSON.stringify(this.vehicleDetails));
    const quoteModel = new QuotationModel();
    quoteModel.UserId = '';
    quoteModel.ManufacturerId = this.vehicleDetails.ManufacturerId;
    quoteModel.ModelId = this.vehicleDetails.ModelId;
    quoteModel.VariantId = this.vehicleDetails.VariantId;
    quoteModel.SeatingCapacity = this.vehicleDetails.SeatingCapacity;
    quoteModel.CubicCapacity = this.vehicleDetails.CubicCapacity;
    quoteModel.FuelType = this.vehicleDetails.FuelType;
    quoteModel.VehicleType = 5;
    quoteModel.BuyerState == this.vehicleDetails.RegistrationCode.substr(2);
    quoteModel.ManufacturerYear = this.vehicleDetails.ManufacturerYear;
    quoteModel.RegistrationCode = this.vehicleDetails.RegistrationCode;

    quoteModel.DayOfPurchase = this.vehicleDetails.DayOfPurchase;
    quoteModel.MonthOfPurchase = this.vehicleDetails.MonthOfPurchase;
    quoteModel.YearOfPurchase = this.vehicleDetails.YearOfPurchase;
    quoteModel.DateOfRegistration = this.vehicleDetails.DateOfRegistration;
    quoteModel.CurrentPolicyType = 'RollOver';
    //quoteModel.PolicyType=this.vehicleDetails.PolicyType;
    quoteModel.PolicyType = "Comprehensive";
    quoteModel.PreviousPolicyType = "Comprehensive";//this.vehicleDetails.PolicyType; 

    if (quoteModel.RegistrationCode != '' && (sessionStorage.getItem('RegCode') == '' || sessionStorage.getItem('RegCode') == null)) {
      //this.getCity(quoteModel.RegistrationCode);

      this.masterService.GetCityOfRegistration(quoteModel.RegistrationCode).subscribe(result => {
        this.registrationCode = result;
        sessionStorage.setItem('RegCode', result[0].CityId);

        quoteModel.CityOfRegistration = sessionStorage.getItem('RegCode');
        quoteModel.ClaimMade = "Yes";
        quoteModel.AutomobileMembership = "No";
        quoteModel.LastNCB = null;
        quoteModel.PACoverOwner = "No";
        quoteModel.OwnedBy = "Individual";
        quoteModel.AntiTheftDevices = "No";
        quoteModel.CNGKit = "Not Fitted";
        quoteModel.GeoGraphicalExtension = "No";
        quoteModel.PaidDriver = "No";
        quoteModel.RestrictTPPDLiability = "No";
        quoteModel.PACover = "No";
        //quoteModel.PreviousPolicyExpirDate=this.vehicleDetails.PolicyExpiryDate;
        this.motorService.postQuoteData(quoteModel).subscribe((result: any) => {
          if (result && result.status == "done")
            sessionStorage.setItem("sessionId", result.Id);

          this.router.navigate(['/car-coverage'], { queryParams: { sessionId: result.Id } })
          // this.router.navigateByUrl('/two-wheeler-quotation?sessionId='+result.Id);
        })
      })

    }
    else {
      //this.delay(3000).then(any => {
      quoteModel.CityOfRegistration = sessionStorage.getItem('RegCode');
      quoteModel.ClaimMade = "Yes";
      quoteModel.AutomobileMembership = "No";
      quoteModel.LastNCB = null;
      quoteModel.PACoverOwner = "No";
      quoteModel.OwnedBy = "Individual";
      quoteModel.AntiTheftDevices = "No";
      quoteModel.CNGKit = "Not Fitted";
      quoteModel.GeoGraphicalExtension = "No";
      quoteModel.PaidDriver = "No";
      quoteModel.RestrictTPPDLiability = "No";
      quoteModel.PACover = "No";
      //quoteModel.PreviousPolicyExpirDate=this.vehicleDetails.PolicyExpiryDate;
      this.motorService.postQuoteData(quoteModel).subscribe((result: any) => {
        if (result && result.status == "done")
          sessionStorage.setItem("sessionId", result.Id);

        this.router.navigate(['/car-coverage'], { queryParams: { sessionId: result.Id } })
        // this.router.navigateByUrl('/two-wheeler-quotation?sessionId='+result.Id);
      })
      //});
    }
  }

  registrationCode: any;
  getCity(rtoCode) {

    this.masterService.GetCityOfRegistration(rtoCode).subscribe(result => {
      this.registrationCode = result;
      sessionStorage.setItem('RegCode', result[0].CityId);
    })
  }
  onBlurMethod(val) {
    document.getElementById("mobile").style.border = "1px solid #A5A5A5";
    const regex = /^[A-Z]{2}\s[0-9]{2}\s[A-Z]{2}\s[0-9]{4}$/gm;
    const str = val;
    let m;
    if (m = regex.exec(str) == null) {

      document.getElementById("mobile").style.border = "1px solid red";

    } else {
      while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          //alert(`Found match, group ${groupIndex}: ${match}`);              

        });
      }
    }
  }


  registrationNoMasking() {
    $("input[name='regNumber']").on("keyup", function () {
      debugger;
      $("input[name='number']").val(destroyMask(this.value));
      this.value = createMask($("input[name='number']").val());
    });

    var full = 0; var trueLen = 0;
    function createMask(string) {

      console.log(string)
      console.log(string.length);
      var last4char = '';
      var reg = /^\d+$/;
      if (string.length > 8) {
        last4char = string.substr(string.length - 4)
      }
      if (last4char != '') {
        if (reg.test(last4char)) {
          full = 1;
          trueLen = string.length;
          console.log(full);
          if (string.length == 9) {
            return string.replace(/(\w{2})(\w{2})(\w{1})(\d{4})/, "$1-$2-$3-$4");
          }
          if (string.length == 10) {
            return string.replace(/(\w{2})(\w{2})(\w{2})(\d{4})/, "$1-$2-$3-$4");
          }
          if (string.length == 11) {
            return string.replace(/(\w{2})(\w{2})(\w{3})(\d{4})/, "$1-$2-$3-$4");
          }

        }
        else return string;
      }

      else return string;

    }

    function destroyMask(string) {
      console.log(string)
      console.log(full)
      if (full == 1) {
        console.log('trueLen ' + trueLen)
        return string.replace(/\W/g, '').substring(0, trueLen);
      }

      var last4char = '';
      var reg = /^\d+$/;
      if (string.length > 8) {
        last4char = string.substr(string.length - 4)
      }
      if (last4char != '') {
        if (reg.test(last4char)) {
          return string.replace(/\W/g, '').substring(0, string.length);
        }
      }

      return string.replace(/\W/g, '').substring(0, 14);
    }
  }


  validateRegistrationNo(regNo) {
    debugger
    const regex = /^[A-Z]{2}[-][0-9A-Z]{1,2}[-](?:[A-Z])?(?:[A-Z]*)[-][0-9]{4}$/gm;
    const str = regNo.toUpperCase();
    let m;
    if (m = regex.exec(str) == null) {
      return false;
    }
    return true;


  }

  sendotp() {
    this.verifyotp = true;

  }

  SaveRiskcareUser() {
    debugger;

    sessionStorage.setItem("CustomerEmail", $("#Email").val());
    sessionStorage.setItem("CustomerMobile", $("#Mobile").val());

    this.motorService.SaveRiskcareUser($("#Name").val(), $("#Email").val(), $("#Mobile").val()).subscribe((res: any) => {
      if (res == true) {
        let data = res;
      }

    });
  }
  
  ProceedToCarNo() {
    if ($("#Name").val() == '' || $("#Name").val() == undefined) {
      Swal.fire("", "Kindly Enter Name", "error");
    }
    // if ($("#Email").val() == '' || $("#Email").val() == undefined) {
    //   Swal.fire("", "Kindly Enter Emailid", "error");
    //   return;
    // }
    if (this.isMobileOTPVerified == true) {
      this.divCarNo = true;
      this.divOTP = false;
      this.SaveRiskcareUser();
    }
    else {
      Swal.fire("", "Kindly Verify Mobile Number", "error");
      return;
    }
  }
  captureOTP(OTPType: any) {
    debugger;
    // this.submitted = true;

    // if (OTPType == 'M' && ($("#Email").val() == '' || $("#Email").val() == undefined)) {
    //   Swal.fire("", "Kindly Enter Emailid", "error");
    //   return;
    // }
    if (OTPType == 'M' && ($("#Mobile").val() == '' || $("#Mobile").val() == undefined)) {
      Swal.fire("", "Kindly Enter Mobile Number", "error");
      return;
    }
    // this.verifyotp=true;
    this.SaveRiskcareUser();

    var val = {
      IsActive: true,
      // Name: $("#Name").val(),
      UserId: $("#Mobile").val(),
      MobileNo: $("#Mobile").val(),
      OTPExpiry: "",
      EmailId: $("#Email").val(),
      Remarks: OTPType,
      Status: "",
      CreatedBy: "",
      CreatedByIP: ""
    }

    // this.spinner.show();

    this.motorService.SendOTP(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        if (OTPType == 'M')
          Swal.fire("", "OTP sent to mobile number", "success");

        else
          Swal.fire("", "OTP sent to Email id", "success");

        this.verifyotp = true;
      }
      else {
        if (data.ErrorMessage != "") {
          Swal.fire("", data.ErrorMessage, "error");

        }
        else {
          Swal.fire("", "OTP sending failed", "error");

        }
        this.verifyotp = false;
        // this.MobileNumber = '';
      }

    });
  }

  // OTPValidation(OTPType: any) {


  //   if (OTPType == 'M' && ($("#MOTP").val() == '' || $("#MOTP").val() == undefined)) {
  //     Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
  //     return;
  //   }

  //   var val = {
  //     IsActive: true,
  //     UserId: $("#Mobile").val(),
  //     MobileNo: $("#Mobile").val(),
  //     OTPExpiry: "",
  //     EmailId: $("#Email").val(),
  //     Remarks: "",
  //     Status: "",
  //     CreatedBy: "",
  //     CreatedByIP: ""
  //   }
  //   this.motorService.OTPValidation(val).subscribe((data: any) => {
  //     debugger;


  //     if (OTPType == 'M' && ($("#MOTP").val() == data[0].OTP)) {
  //       Swal.fire("", "Mobile OTP is correct", "success");
  //       this.isMobileOTPVerified = true;
  //     }
  //     else if (OTPType == 'M' && ($("#MOTP").val() != data[0].OTP)) {
  //       Swal.fire("", "Mobile OTP is not correct", "error");
  //     }
  //     else {
  //       Swal.fire("", "Please provide correct OTP", "error");
  //     }

  //   });


  // }

  OTPValidation(OTPType: any) {
    debugger;
    if (OTPType === "M" && ($("#MOTP").val() === "" || $("#MOTP").val() === undefined)) {
        Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
        return;
    }

    var val = {
        IsActive: true,
        UserId: $("#Mobile").val(),
        MobileNo: $("#Mobile").val(),
        OTP: $("#MOTP").val(), 
        EmailId: $("#Email").val(),
        Remarks: "",
        Status: "",
        CreatedBy: "",
        CreatedByIP: "",
    };
    
    sessionStorage.setItem("MobileNo", $("#Mobile").val());

    this.motorService.OTPValidation(val).subscribe((data: any) => {
        debugger;

        if (data.success) {
            Swal.fire("", "Login Successful", "success");
            this.isMobileOTPVerified = true;
        } else {
            Swal.fire("", data.message || "Please provide correct OTP", "error");
        }
    });
}

}


