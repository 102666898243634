import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EncrDecrService } from 'src/app/services/EncrDecrService';
import { MasterService } from 'src/app/services/master.service';
import { MotorService } from 'src/app/services/motor.service';
import  {twoWheelerDetails,QuotationModel}  from '../../common/Models/twoWheeler'
import Swal from 'sweetalert2';
import { User ,UserAuthentication} from 'src/app/common/Models/userDetail';
import { GlobalService } from 'src/app/global.service';
import { token } from 'src/app/common/Models/token';
declare var $: any;

@Component({
  selector: 'app-bike-insurance',
  templateUrl: './bike-insurance.component.html',
  styleUrls: ['./bike-insurance.component.css']
})
export class BikeInsuranceComponent implements OnInit {
  public customPatterns = { 'A': { pattern: new RegExp('/^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/')} };
  token:token;
  ErrorHandle:ErrorHandle;
  @ViewChild("registrationNumber", { static: false }) registrationNumber: ElementRef;
  isLogedUser:boolean=false;
  selectBike:string;
  isHaveBikeNumber:boolean=false;
  //full:number=0; 
  vehicleDetails:QuotationModel;
  
  divCarNo: boolean = false;
  divOTP: boolean = true;
  verifyotp: boolean = false;
  isMobileOTPVerified: boolean = false;
  newRegNumber: string;
  constructor(private router:Router,private masterService:MasterService,private routerActive:ActivatedRoute,
    private EncrDecr: EncrDecrService,private authService:AuthenticationService,
    private globalService:GlobalService,private motorService:MotorService) { }

  ngOnInit(): void {
    debugger;
  sessionStorage.setItem('vehicleEngineno', "");
    sessionStorage.setItem('vehicleChassino', "");
    $("#haveBikeNumber1").click(function(){
      $(this).toggleClass("active");
    });
    this.registrationNoMasking();
    
    localStorage.setItem('UserId',''); 
  }
  HaveBikeNumber(bikeType){    
    this.selectBike=bikeType;
    if(this.selectBike=="NEW"||this.selectBike=="NO"){
      sessionStorage.removeItem('registrationNumber'); 
      sessionStorage.removeItem('vehicledata');
      if(this.selectBike=="NO")this.selectBike="ROLLOVER"
      this.router.navigateByUrl('/bike-rto?type='+this.selectBike);
    }
  }
  
  CloseModal(){
    $('#withoutModal').hide();
        $('.modal-show').hide();
  }

  formatRegNumber(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value.trim();

    if (/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{2}[0-9]{4}$/.test(value)) {
        let formatted = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
        formatted = formatted.replace(/(.{2})(.{2})(.{2})(.*)/, "$1-$2-$3-$4");
        inputElement.value = formatted;
        this.newRegNumber = formatted;
    }
}

  getRtoDetails() {
    
    debugger;
    // let registrationNumber = this.registrationNumber.nativeElement.value.toUpperCase();
    let registrationNumber = this.newRegNumber;
    if (registrationNumber == "") {
      Swal.fire('Please enter registration number', '', 'error');
      return false;
    }
    if (!this.validateRegistrationNo(registrationNumber)) {
      Swal.fire('Please enter valid registration number', '', 'error');
      return false;
    }
    let regNo = registrationNumber;


    this.motorService.getVehicleByRegNo(regNo.replace(' ', '')).subscribe((data: QuotationModel) => {
      debugger;
      if (data) {
        
        sessionStorage.setItem('registrationNumber', registrationNumber);
        sessionStorage.setItem('vehicledata', JSON.stringify(data));
        this.vehicleDetails = data;
        
        this.vehicleDetails.RegistrationCode = registrationNumber.toUpperCase().substring(0, 5);
        
        this.masterService.saveVehicle(data).subscribe(res => {
          
          this.saveQuote();
        })

      }
      else {
debugger;
        this.motorService.getflaVehicleByRegNo(regNo.replace(' ', '')).subscribe((fladata: QuotationModel) => {
          if (fladata) {
            sessionStorage.setItem('registrationNumber', registrationNumber);
            sessionStorage.setItem('vehicledata', JSON.stringify(fladata));
            this.vehicleDetails = fladata;
            sessionStorage.setItem('vehicleEngineno', fladata.vehicleEngineno);
            sessionStorage.setItem('vehicleChassino', fladata.vehicleChassino);
            this.vehicleDetails.RegistrationCode = registrationNumber.toUpperCase().substring(0, 5);
            this.vehicleDetails.YearOfPurchase = fladata.YearOfPurchase;
            this.vehicleDetails.MonthOfPurchase = fladata.MonthOfPurchase;
            this.vehicleDetails.DayOfPurchase = fladata.DayOfPurchase;
            this.vehicleDetails.DateOfRegistration = fladata.DateOfRegistration;
            if (fladata.fla_vh_class_desc.toUpperCase() == "LMV") {
              Swal.fire('This vehicle number does belongs to Private car vehicle.', '', 'error');
              this.router.navigateByUrl('/');
              return false;
            }
            this.masterService.saveVehicle(fladata).subscribe(res => {
              
              this.saveQuote();
            })
          }
          else {
            this.vehicleDetails.RegistrationCode = registrationNumber.substring(0, 5);
            // this.vehicleDetails.RegistrationCode=registrationNumber;
            debugger;
            this.router.navigateByUrl('/bike-rto?type=ROLLOVER');
          }
        }, ((err: ErrorHandle) => {
          this.ErrorHandle = err;
          // if (err.status == 404) {
            sessionStorage.setItem('registrationNumber', regNo);
            let vehicledata = new twoWheelerDetails();
            vehicledata.RegistrationNo = regNo;
            vehicledata.RegistrationCode = regNo.substring(0, 5);
            // vehicledata.RegistrationCode=regNo;
            sessionStorage.setItem('vehicledata', JSON.stringify(vehicledata));
            Swal.fire('Please enter your vehicle details manually', '', 'error');
            debugger;
            this.router.navigateByUrl('/bike-rto?type=ROLLOVER');
          // }
          if (this.ErrorHandle.statusText == "Bad Request") {
            Swal.fire('Userid and password are incorrect.', '', 'error');
          }
        }));

      }
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
       if (err.status == 404) {
        sessionStorage.setItem('registrationNumber', regNo);
        let vehicledata = new twoWheelerDetails();
        vehicledata.RegistrationNo = regNo;
        vehicledata.RegistrationCode = regNo.substring(0, 5);
        // vehicledata.RegistrationCode=regNo;
        sessionStorage.setItem('vehicledata', JSON.stringify(vehicledata));
        
        //this.router.navigateByUrl('/bike-rto?type=ROLLOVER');
      }
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Userid and password are incorrect.', '', 'error');
      }
    }));
    
      this.motorService.getflaVehicleByRegNo(regNo.replace(' ', '')).subscribe((fladata: QuotationModel) => {
        if (fladata) {
          sessionStorage.setItem('registrationNumber', registrationNumber);
          sessionStorage.setItem('vehicledata', JSON.stringify(fladata));
          this.vehicleDetails = fladata;
          sessionStorage.setItem('vehicleEngineno', fladata.vehicleEngineno);
          sessionStorage.setItem('vehicleChassino', fladata.vehicleChassino);
          this.vehicleDetails.RegistrationCode = registrationNumber.toUpperCase().substring(0, 5);
          this.vehicleDetails.YearOfPurchase = fladata.YearOfPurchase;
          this.vehicleDetails.MonthOfPurchase = fladata.MonthOfPurchase;
          this.vehicleDetails.DayOfPurchase = fladata.DayOfPurchase;
          this.vehicleDetails.DateOfRegistration = fladata.DateOfRegistration;
          if (fladata.fla_vh_class_desc.toUpperCase() == "LMV") {
            Swal.fire('This vehicle number does belongs to Private car vehicle.', '', 'error');
            this.router.navigateByUrl('/');
            return false;
          }
          this.masterService.saveVehicle(fladata).subscribe(res => {
            
            this.saveQuote();
          })
        }
        else {
          this.vehicleDetails.RegistrationCode = registrationNumber.substring(0, 5);
          // this.vehicleDetails.RegistrationCode=registrationNumber;
          debugger;
          this.router.navigateByUrl('/bike-rto?type=ROLLOVER');
        }
      }, ((err: ErrorHandle) => {
        this.ErrorHandle = err;
        // if (err.status == 404) {
          sessionStorage.setItem('registrationNumber', regNo);
          let vehicledata = new twoWheelerDetails();
          vehicledata.RegistrationNo = regNo;
          vehicledata.RegistrationCode = regNo.substring(0, 5);
          // vehicledata.RegistrationCode=regNo;
          sessionStorage.setItem('vehicledata', JSON.stringify(vehicledata));
          Swal.fire('Please enter your vehicle details manually', '', 'error');
          debugger;
          this.router.navigateByUrl('/bike-rto?type=ROLLOVER');
        // }
        if (this.ErrorHandle.statusText == "Bad Request") {
          Swal.fire('Userid and password are incorrect.', '', 'error');
        }
      }));

    

  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  saveQuote() {

    sessionStorage.setItem('vehicledata', JSON.stringify(this.vehicleDetails));
    const quoteModel = new QuotationModel();
    
      quoteModel.UserId = '';
    quoteModel.ManufacturerId = this.vehicleDetails.ManufacturerId;
    quoteModel.ModelId = this.vehicleDetails.ModelId;
    quoteModel.VariantId = this.vehicleDetails.VariantId;
    quoteModel.SeatingCapacity = this.vehicleDetails.SeatingCapacity;
    quoteModel.CubicCapacity = this.vehicleDetails.CubicCapacity;
    quoteModel.FuelType = this.vehicleDetails.FuelType;
    quoteModel.VehicleType = 3;
    quoteModel.BuyerState == this.vehicleDetails.RegistrationCode.substr(2);
    quoteModel.ManufacturerYear = this.vehicleDetails.ManufacturerYear;
    quoteModel.RegistrationCode = this.vehicleDetails.RegistrationCode;

    quoteModel.DayOfPurchase = this.vehicleDetails.DayOfPurchase;
    quoteModel.MonthOfPurchase = this.vehicleDetails.MonthOfPurchase;
    quoteModel.YearOfPurchase = this.vehicleDetails.YearOfPurchase;
    quoteModel.DateOfRegistration = this.vehicleDetails.DateOfRegistration;
    quoteModel.CurrentPolicyType = 'RollOver';
    //quoteModel.PolicyType=this.vehicleDetails.PolicyType;
    quoteModel.PolicyType = "Comprehensive";
    quoteModel.PreviousPolicyType = "Comprehensive";//this.vehicleDetails.PolicyType; 
    debugger
    if (quoteModel.RegistrationCode != '' && (sessionStorage.getItem('RegCode') == '' || sessionStorage.getItem('RegCode') == null)) {
      //this.getCity(quoteModel.RegistrationCode);

      this.masterService.GetCityOfRegistration(quoteModel.RegistrationCode).subscribe(result => {
        this.registrationCode = result;
        sessionStorage.setItem('RegCode', result[0].CityId);

        quoteModel.CityOfRegistration = sessionStorage.getItem('RegCode');

        quoteModel.ClaimMade = "Yes";
        quoteModel.AutomobileMembership = "No";
        quoteModel.LastNCB = null;
        quoteModel.PACoverOwner = "No";
        quoteModel.OwnedBy = "Individual";
        quoteModel.AntiTheftDevices = "No";
        quoteModel.CNGKit = "Not Fitted";
        quoteModel.GeoGraphicalExtension = "No";
        quoteModel.PaidDriver = "No";
        quoteModel.RestrictTPPDLiability = "No";
        quoteModel.PACover = "No";
        //quoteModel.PreviousPolicyExpirDate=this.vehicleDetails.PolicyExpiryDate;
        this.motorService.postQuoteData(quoteModel).subscribe((result: any) => {
          if (result && result.status == "done")
            sessionStorage.setItem("sessionId", result.Id);

          this.router.navigate(['/bike-coverage'], { queryParams: { sessionId: result.Id } })
          // this.router.navigateByUrl('/two-wheeler-quotation?sessionId='+result.Id);
        })
      })
    }
    else {
      //this.delay(3000).then(any => {
      debugger
      quoteModel.CityOfRegistration = sessionStorage.getItem('RegCode');

      quoteModel.ClaimMade = "Yes";
      quoteModel.AutomobileMembership = "No";
      quoteModel.LastNCB = null;
      quoteModel.PACoverOwner = "No";
      quoteModel.OwnedBy = "Individual";
      quoteModel.AntiTheftDevices = "No";
      quoteModel.CNGKit = "Not Fitted";
      quoteModel.GeoGraphicalExtension = "No";
      quoteModel.PaidDriver = "No";
      quoteModel.RestrictTPPDLiability = "No";
      quoteModel.PACover = "No";
      //quoteModel.PreviousPolicyExpirDate=this.vehicleDetails.PolicyExpiryDate;
      this.motorService.postQuoteData(quoteModel).subscribe((result: any) => {
        if (result && result.status == "done")
          sessionStorage.setItem("sessionId", result.Id);

        this.router.navigate(['/bike-coverage'], { queryParams: { sessionId: result.Id } })
        // this.router.navigateByUrl('/two-wheeler-quotation?sessionId='+result.Id);
      })

      // });
    }


  }
  registrationCode: any;
  getCity(rtoCode) {

    this.masterService.GetCityOfRegistration(rtoCode).subscribe(result => {
      this.registrationCode = result;
      sessionStorage.setItem('RegCode', result[0].CityId);
    })
  }
    onBlurMethod(val){
      document.getElementById("mobile").style.border = "1px solid #A5A5A5";
      const regex = /^[A-Z]{2}\s[0-9]{2}\s[A-Z]{2}\s[0-9]{4}$/gm;
      const str = val;
      let m;
      if(m = regex.exec(str) == null){
    
        document.getElementById("mobile").style.border = "1px solid red";
        
      }else{
          while ((m = regex.exec(str)) !== null) {        
              if (m.index === regex.lastIndex) {
                  regex.lastIndex++;
              }
              m.forEach((match, groupIndex) => {
                  //alert(`Found match, group ${groupIndex}: ${match}`);              
                  
              });
          }
      }  
     }


     registrationNoMasking(){
      $("input[name='regNumber']").on("keyup", function(){
        $("input[name='number']").val(destroyMask(this.value));
      this.value = createMask($("input[name='number']").val());
    });
    
    
    
    var full=0;var trueLen=0;
    function createMask(string){

      console.log(string)
       console.log(string.length);
       var last4char='';
       var reg = /^\d+$/;
       if(string.length>8)
       {
        last4char=string.substr(string.length - 4) 
       }
      if(last4char!='')
      {
        if(reg.test(last4char))
        {   full=1;
           trueLen=string.length;
           console.log(full);
           if(string.length==9){
            return string.replace(/(\w{2})(\w{2})(\w{1})(\d{4})/,"$1-$2-$3-$4");
           }
           if(string.length==10){
             return string.replace(/(\w{2})(\w{2})(\w{2})(\d{4})/,"$1-$2-$3-$4");
           }
           if(string.length==11){
            return string.replace(/(\w{2})(\w{2})(\w{3})(\d{4})/,"$1-$2-$3-$4");
           }
          
        }  
        else return string;
      }
                                                    
      else return string;
      
    } 
    
    function destroyMask(string){
      console.log(string)
      console.log(full)
       if(full==1){
        console.log('trueLen '+trueLen)
       return string.replace(/\W/g,'').substring(0, trueLen);
       }  
      
       var last4char='';
       var reg = /^\d+$/;
       if(string.length>8)
       {
        last4char=string.substr(string.length - 4) 
       }
      if(last4char!='')
      {
         if(reg.test(last4char)){
            return string.replace(/\W/g,'').substring(0, string.length);
         }
       }
      
        return string.replace(/\W/g,'').substring(0, 14);
    }
  }


  validateRegistrationNo(regNo){  
    debugger
    const regex = /^[A-Z]{2}[-][0-9A-Z]{1,2}[-](?:[A-Z])?(?:[A-Z]*)[-][0-9]{4}$/gm;
    const str = regNo.toUpperCase();
    let m;
    if(m = regex.exec(str) == null){
      return false;
    }
    return true;
   
  }

  SaveRiskcareUser(){
    debugger;
    
  sessionStorage.setItem("CustomerEmail",$("#Email").val());
  sessionStorage.setItem("CustomerMobile",$("#Mobile").val());

    this.motorService.SaveRiskcareUser($("#Name").val() ,$("#Email").val(),$("#Mobile").val()).subscribe((res:any)=>{   
      if(res==true){
        let data=res;
        // Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
      }
      
    });
  }
  ProceedToCarNo(){
    debugger;
    if($("#Name").val() == '' || $("#Name").val() == undefined) {
      Swal.fire("", "Kindly Enter Name", "error");
    }
    // if ($("#Email").val() == '' || $("#Email").val() == undefined) {
    //   Swal.fire("", "Kindly Enter Emailid", "error");
    //   return;
    // }
    if(this.isMobileOTPVerified==true){
  this.divCarNo=true;
  this.divOTP=false;
    }
    else{
      Swal.fire("", "Kindly Verify Mobile Number", "error");
      return;
    }
  }
  captureOTP(OTPType: any) {
    debugger;
    // this.submitted = true;
    
    // if (OTPType == 'M' && ($("#Email").val() == '' || $("#Email").val() == undefined)) {
    //   Swal.fire("", "Kindly Enter Emailid", "error");
    //   return;
    // }
    if (OTPType == 'M' && ($("#Mobile").val() == '' || $("#Mobile").val() == undefined)) {
      Swal.fire("", "Kindly Enter Mobile Number", "error");
      return;
    }
    // this.verifyotp=true;
    this.SaveRiskcareUser();
    var val = {
      IsActive: true,
      UserId: $("#Mobile").val(),
      MobileNo: $("#Mobile").val(),
      OTPExpiry: "",
      EmailId: $("#Email").val(),
      Remarks: OTPType,
      Status: "",
      CreatedBy: "",
      CreatedByIP: ""
    }
  
    // this.spinner.show();
  
    this.motorService.SendOTP(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        // this.otpBox = "Y";
        // this.phoneBox = "N";
        if (OTPType == 'M')
          Swal.fire("", "OTP sent to mobile number", "success");
        else
          Swal.fire("", "OTP sent to Email id", "success");

          this.verifyotp=true;
      }
      else {
        if(data.ErrorMessage!=""){
          Swal.fire("", data.ErrorMessage, "error");
        }
        else{
          Swal.fire("", "OTP sending failed", "error");
        }
        this.verifyotp=false;
        // this.MobileNumber = '';
      }
  
    });
  }
  
    // OTPValidation(OTPType: any) {
  
    //   // this.submitted = true;
      
    //   if (OTPType == 'M' && ($("#MOTP").val() == '' || $("#MOTP").val() == undefined)) {
    //     Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
    //     return;
    //   }
  
    //   // if (OTPType == 'E' && (this.EmailOTP == '' || this.EmailOTP == undefined)) {
    //   //   Swal.fire("", "Kindly Enter OTP sent on your Email id", "error");
    //   //   return;
    //   // }
  
    //   var val = {
    //     IsActive: true,
    //     UserId: $("#Mobile").val(),
    //     MobileNo: $("#Mobile").val(),
    //     OTPExpiry: "",
    //     EmailId: $("#Email").val(),
    //     Remarks: "",
    //     Status: "",
    //     CreatedBy: "",
    //     CreatedByIP: ""
    //   }
    //   this.motorService.OTPValidation(val).subscribe((data: any) => {
    //     debugger;
        
  
    //     if (OTPType == 'M' && ($("#MOTP").val() == data[0].OTP)) {
    //       Swal.fire("", "Mobile OTP is correct", "success");
    //       this.isMobileOTPVerified = true;
    //     }
    //     // else if (OTPType == 'E' && (this.EmailOTP == data[0].EOTP)) {
    //     //   //Swal.fire("", "Email OTP is correct", "success");
    //     //   this.isEmailOTPVerified = true;
    //     // }
    //     else if (OTPType == 'M' && ($("#MOTP").val() != data[0].OTP)) {
    //       Swal.fire("", "Mobile OTP is not correct", "error");
    //     }
    //     // else if (OTPType == 'E' && (this.EmailOTP != data[0].EOTP)) {
    //     //   Swal.fire("", "Email OTP is not correct", "error");
    //     // }
    //     else {
    //       Swal.fire("", "Please provide correct OTP", "error");
    //     }
  
    //     //}
    //   });
  
    //   ////
  
    // }

    OTPValidation(OTPType: any) {
      debugger;
      if (OTPType === "M" && ($("#MOTP").val() === "" || $("#MOTP").val() === undefined)) {
          Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
          return;
      }
  
      var val = {
          IsActive: true,
          UserId: $("#Mobile").val(),
          MobileNo: $("#Mobile").val(),
          OTP: $("#MOTP").val(), 
          EmailId: $("#Email").val(),
          Remarks: "",
          Status: "",
          CreatedBy: "",
          CreatedByIP: "",
      };
      
      sessionStorage.setItem("MobileNo", $("#Mobile").val());
  
      this.motorService.OTPValidation(val).subscribe((data: any) => {
          debugger;
  
          if (data.success) {
              Swal.fire("", "Login Successful", "success");
              this.isMobileOTPVerified = true;
          } else {
              Swal.fire("", data.message || "Please provide correct OTP", "error");
          }
      });
  }

}
