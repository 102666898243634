import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  QuotationModel,
  premiumModel,
  addonMaster,
  addon,
  twoWheelerDetails,
} from "../../common/Models/twoWheeler";

import { Router, ActivatedRoute } from "@angular/router";
import { MotorService } from "src/app/services/motor.service";
import { MasterService } from "src/app/services/master.service";
import Swal from "sweetalert2";
import { voluntryExcess } from "src/app/common/Models/motor.master";

import {
  MomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-car-coverage",
  templateUrl: "./car-coverage.component.html",
  styleUrls: ["./car-coverage.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CarCoverageComponent implements OnInit {
  addonList: addon[] = [];
  isNcbDeclear: boolean = false;
  isPrevPolDtls: boolean = false;
  quoteModel: QuotationModel = new QuotationModel();
  voluntaryExcess: voluntryExcess[] = [];
  addonCollection: any[] = [];
  vehicleData: twoWheelerDetails;
  modalPoup: String = "divClose";
  dynAddons = [];
  minDate: Date;
  maxDate: Date;
  ischeckprevpolicy: boolean = true;
  isDeclare: boolean;
  isSAOD: boolean = true;
  expdate;
  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private motorService: MotorService,
    private masterService: MasterService
  ) {
    debugger;
    const currentDate = new Date();

    this.minDate = new Date();
    if (this.quoteModel.PolicyType == "ThirdParty") {
      this.minDate.setDate(currentDate.getDate() - 90);
    }
    currentDate.setDate(currentDate.getDate() + 90);
    this.maxDate = currentDate;
    this.expdate = new FormControl(this.minDate);
  }

  ngOnInit(): void {
    $(".modal-backdrop").remove();
    this.getQuoteData();
    $(".carousel").carousel({
      interval: false,
    });
    const currentDate = new Date();
    if (this.quoteModel.PolicyType == "ThirdParty") {
      this.minDate.setDate(currentDate.getDate() - 90);
      sessionStorage.setItem("PrevPolDtls", "Yes");
      this.quoteModel.isPrevPolDtls = "Yes";
      $("#divPrevPolDtls").css("display", "none");
      this.quoteModel.PreviousPolicyType = "comprehensive";
    } else {
      this.minDate.setDate(currentDate.getDate() - 90);
      sessionStorage.setItem("PrevPolDtls", "Yes");
      this.quoteModel.isPrevPolDtls = "Yes";

      $("#divPrevPolDtls").css("display", "none");
      this.quoteModel.PreviousPolicyType = "comprehensive";
    }
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  getAddons() {
    debugger;
    this.masterService
      .getAddons(this.quoteModel.VehicleType)
      .subscribe((res: addon[]) => {
        this.addonList = res;
        if (this.addonList.length > 0) {
          this.addonList.forEach((v, i) => {
            this.dynAddons[v.AddOnId] = "No";
          });
        }
      });
  }

  checkUncheckAll() {
    debugger;

    if ($("#chkAddon")[0].checked) {
      this.addonList.forEach((v, i) => {
        this.addonCollection.push(v.AddOnId);
        this.dynAddons[v.AddOnId] = "Yes";
      });
    } else {
      this.addonCollection = [];
      this.addonList.forEach((v, i) => {
        this.dynAddons[v.AddOnId] = "No";
      });
    }
  }

  getVoluntaryExcess() {
    this.masterService
      .getVoluntryExcess(this.quoteModel.VehicleType)
      .subscribe((res: voluntryExcess[]) => {
        this.voluntaryExcess = res;
      });
  }
  addCollection(value, addonId) {
    let idx = -1;

    let found = this.addonList.find((element) => element.AddOnId == addonId);
    if (this.addonCollection.length > 0)
      idx = this.addonCollection.indexOf(addonId);
    if (idx < 0 && value == "Yes") {
      this.addonCollection.push(addonId);
      if (this.dynAddons[addonId] == value) this.dynAddons[addonId] = "";
      else this.dynAddons[addonId] = "Yes";
    } else {
      this.addonCollection.splice(idx, 1);
      if (this.dynAddons[addonId] == value) this.dynAddons[addonId] = "";
      else this.dynAddons[addonId] = "No";
    }
  }
  goPolicyType(policyType) {
    this.quoteModel.PolicyType = policyType;
    this.quoteModel.PreviousPolicyType = policyType;
    if (this.quoteModel.PolicyType == "StandaloneOD") {
      this.quoteModel.PACoverOwner = "No";
    } else {
      this.quoteModel.PACoverOwner = "Yes";
    }
    this.quoteModel.PaidDriver = "No";
    this.quoteModel.RestrictTPPDLiability = "No";
    this.quoteModel.AutomobileMembership = "No";
    this.quoteModel.PACover = "No";

    if (policyType == "ThirdParty") {
      this.quoteModel.ClaimMade = "No";
      this.quoteModel.CurrentNCB = "0";
      this.isNcbDeclear = true;
    }
  }

  getQuoteData() {
    debugger;
    let id = this.activeRouter.snapshot.queryParamMap.get("sessionId");
    this.motorService
      .getQuotationQuery("", id, 5)
      .subscribe((result: QuotationModel) => {
        this.quoteModel = result;
        if (this.quoteModel.CurrentPolicyType != "NEW")
          this.quoteModel.CurrentPolicyType = "";
        this.quoteModel.CurrentNCB = "";
        this.quoteModel.ClaimMade = "";
        this.quoteModel.PreviousPolicyExpirDate = "";
        this.expdate = new FormControl(this.quoteModel.PreviousPolicyExpirDate);
        this.quoteModel.LastNCB = "";
        this.goPolicyType(this.quoteModel.PolicyType);
        this.getAddons();
        this.getVoluntaryExcess();
        this.quoteModel.UserId = "";
        debugger;

        let regdate = new Date(
          Number(this.quoteModel.YearOfPurchase),
          Number(this.quoteModel.MonthOfPurchase) - 1,
          Number(this.quoteModel.DayOfPurchase)
        );

        let ageDifMs = Date.now() - regdate.getTime();
        let ageDate = new Date(ageDifMs);
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        let septemberFirst2018 = new Date("2018-09-01");
        if (regdate <= septemberFirst2018) {
          this.isSAOD = false;
        }
        if (
          this.quoteModel.FuelType == "CNG" ||
          this.quoteModel.FuelType == "LPG"
        ) {
          this.quoteModel.CNGKit = "Company Fitted";
        }
      });
  }

  applyChanges() {
    debugger;
    let validate = true;
    if (this.quoteModel.PACover == "Yes") {
      if (
        this.quoteModel.SumInsured == "" ||
        this.quoteModel.SumInsured == null
      ) {
        Swal.fire("select suminsured", "", "error");
        validate = false;
      }
    } else {
      this.quoteModel.SumInsured = "";
      validate = true;
    }
    let addons = [];
    if (this.addonCollection.length > 0) {
      for (let i = 0; i < this.addonCollection.length; i++) {
        addons.push({ AddonId: this.addonCollection[i] });
      }
      this.quoteModel.Addons = addons;
    } else {
      this.quoteModel.Addons = null;
    }
    sessionStorage.setItem("addons", JSON.stringify(addons));
    if (validate == true) {
      if (this.quoteModel.CurrentPolicyType != "NEW") $("#myModal").show();
      else {
        this.saveQuote();
      }
    }

    if (this.quoteModel.PolicyType == "ThirdParty") {
      $("#divPrevPolDtls").css("display", "none");
    }
  }
  goClaim() {
    debugger;
    if (this.quoteModel.PolicyType == "ThirdParty") {
      if (
        this.quoteModel.CurrentPolicyType == "RollOver" &&
        this.quoteModel.isPrevPolDtls == "Yes"
      ) {
        if (
          this.quoteModel.PreviousPolicyExpirDate == "" ||
          this.quoteModel.PreviousPolicyExpirDate == null
        ) {
          Swal.fire("Select previous policy expiry date", "", "error");
          return;
        }
      }
      if (this.quoteModel.PolicyType == "ThirdParty") {
        this.quoteModel.isPrevPolDtls = $(
          'input[name="PrevPolDtls"]:checked'
        ).val();
      } else {
        this.quoteModel.isPrevPolDtls = $(
          'input[name="PrevPolDtls"]:checked'
        ).val();
      }

      if (this.quoteModel.CurrentPolicyType != "") {
        if (this.quoteModel.CurrentPolicyType == "Expired") {
          debugger;
          this.saveQuote();
        } else if (
          this.quoteModel.CurrentPolicyType != "Expired" &&
          this.isPrevPolDtls == false
        ) {
          this.saveQuote();
        } else {
          $("#divExpDate").removeClass("active");
          $("#divClaim").addClass("active");
          this.modalPoup = "goClaim";
        }
        if (this.quoteModel.PolicyType == "ThirdParty") {
          this.saveQuote();
        } else {
          $("#divClaim").addClass("active");
        }
      }
      sessionStorage.setItem(
        "PolicyExpirDate",
        this.quoteModel.PreviousPolicyExpirDate
      );
    } else {
      debugger;
      this.quoteModel.isPrevPolDtls = $(
        'input[name="PrevPolDtls"]:checked'
      ).val();
      if (this.quoteModel.CurrentPolicyType == "") {
        Swal.fire("Please select either Yes or No", "", "error");
        return false;
      }
      if (this.quoteModel.CurrentPolicyType != "RollOver") {
      } else {
        if (
          (this.quoteModel.PreviousPolicyExpirDate == "" ||
            this.quoteModel.PreviousPolicyExpirDate == null) &&
          this.quoteModel.isPrevPolDtls == "Yes"
        ) {
          Swal.fire("Please enter previous policy end date", "", "error");
          return false;
        }
      }

      if (this.quoteModel.CurrentPolicyType != "") {
        if (this.quoteModel.CurrentPolicyType == "Expired") {
          if (this.quoteModel.PreviousPolicyExpirDate) {
            var dtToday = new Date();
            var dtexp = new Date();

            dtexp.setFullYear(
              Number(this.quoteModel.PreviousPolicyExpirDate.split("-")[0]),
              Number(this.quoteModel.PreviousPolicyExpirDate.split("-")[1]) - 1,
              Number(this.quoteModel.PreviousPolicyExpirDate.split("-")[2])
            );

            var ageDiffDay = Math.floor(
              (Date.UTC(
                dtToday.getFullYear(),
                dtToday.getMonth(),
                dtToday.getDate()
              ) -
                Date.UTC(
                  dtexp.getFullYear(),
                  dtexp.getMonth(),
                  dtexp.getDate()
                )) /
                (1000 * 60 * 60 * 24)
            );

            var ageDifMs = Date.now() - dtexp.getTime();
            if (ageDiffDay < 91) {
              $("#divExpDate").removeClass("active");
              $("#divClaim").addClass("active");
              this.modalPoup = "goClaim";
            } else {
              this.saveQuote();
            }
          } else {
            this.saveQuote();
          }
        } else if (
          this.quoteModel.CurrentPolicyType != "Expired" &&
          this.quoteModel.isPrevPolDtls == "No"
        ) {
          this.saveQuote();
        } else {
          $("#divExpDate").removeClass("active");
          $("#divClaim").addClass("active");
          this.modalPoup = "goClaim";
        }

        if (this.quoteModel.PolicyType == "ThirdParty") {
          this.saveQuote();
        } else {
          $("#divExpDate").removeClass("active");
        }
      }
      if (this.quoteModel.PolicyType == "ThirdParty") {
        this.saveQuote();
      } else {
      }

      sessionStorage.setItem(
        "PolicyExpirDate",
        this.quoteModel.PreviousPolicyExpirDate
      );
    }
  }

  claimMade(claim, value) {
    debugger;
    if (claim == "Yes") {
      this.quoteModel.ClaimMade = "Yes";
      this.quoteModel.LastNCB = "";
      this.quoteModel.CurrentNCB = "0";
    } else {
      this.quoteModel.ClaimMade = "No";
      this.quoteModel.LastNCB = value.split("-")[0];
      this.quoteModel.CurrentNCB = value.split("-")[1];
    }
  }

  Declare(event) {
    if (event.target.checked) {
      this.isDeclare = true;
    } else {
      this.isDeclare = false;
    }
  }

  // saveQuote() {
  //   debugger
  //   let validate = true;
  //   if (this.quoteModel.PolicyType == "ThirdParty") {
  //     if (this.quoteModel.isPrevPolDtls == "Yes") {
  //       if (!this.ischeckprevpolicy) {
  //         $("#divClaim").addClass("active");
  //         $("#divExpDate").removeClass("active");
  //         $("#PreviousPolicyEndDate").css("display", "none");
  //         const currentDate = new Date();
  //         currentDate.setDate(currentDate.getDate() - 90);
  //         this.minDate = currentDate;

  //         this.maxDate = new Date();
  //       }
  //       this.isPrevPolDtls = true;
  //     }
  //   } else {
  //     debugger;
  //     if (this.quoteModel.isPrevPolDtls == "Yes") {
  //       if (!this.ischeckprevpolicy) {
  //         $("#divClaim").addClass("active");
  //         $("#divExpDate").removeClass("active");
  //         $("#PreviousPolicyEndDate").css("display", "none");
  //         const currentDate = new Date();
  //         currentDate.setDate(currentDate.getDate() - 90);
  //         this.minDate = currentDate;

  //         this.maxDate = new Date();
  //       }
  //       this.isPrevPolDtls = true;
  //     }
  //   }
  //   if (this.quoteModel.AutomobileMembership == "Yes") {
  //     if (
  //       this.quoteModel.MembershipNo == "" ||
  //       this.quoteModel.MembershipNo == null
  //     ) {
  //       Swal.fire("enter membership no", "", "error");
  //       validate = false;
  //     }
  //     if (
  //       this.quoteModel.MembershipExpiry == "" ||
  //       this.quoteModel.MembershipExpiry == null
  //     ) {
  //       Swal.fire("enter membership expiry date", "", "error");
  //       validate = false;
  //     }
  //   }
  //   if (this.quoteModel.PACover == "Yes") {
  //     this.quoteModel.NoOfPassengers = this.quoteModel.SeatingCapacity;
  //     if (
  //       this.quoteModel.SumInsured == "" ||
  //       this.quoteModel.SumInsured == null
  //     ) {
  //       Swal.fire("select suminsured", "", "error");
  //       validate = false;
  //     }
  //   }
  //   if (
  //     this.quoteModel.ClaimMade == "" &&
  //     this.quoteModel.PolicyType != "ThirdParty" &&
  //     this.quoteModel.CurrentPolicyType != "NEW" &&
  //     this.quoteModel.CurrentPolicyType != "Expired"
  //   ) {
  //     if (
  //       this.quoteModel.ClaimMade == "" ||
  //       this.quoteModel.ClaimMade == null
  //     ) {
  //       Swal.fire("Select claim in your policy?", "", "error");
  //       validate = false;
  //     }
  //   }
  //   if (
  //     this.quoteModel.CurrentPolicyType != "Expired" &&
  //     this.quoteModel.ClaimMade == "No"
  //   ) {
  //     if (
  //       this.quoteModel.CurrentNCB == "" ||
  //       this.quoteModel.CurrentNCB == null
  //     ) {
  //       Swal.fire("Select NCB", "", "error");
  //       validate = false;
  //     }
  //   }
  //   if (this.quoteModel.CurrentNCB != "") {
  //     if (this.isNcbDeclear == false) {
  //       Swal.fire(
  //         "Please declare that all the furnished details are correct",
  //         "",
  //         "error"
  //       );
  //       validate = false;
  //     }
  //   }
  //   debugger;
  //   if (validate == true) {
  //     let addons = [];
  //     if (this.addonCollection.length > 0) {
  //       for (let i = 0; i < this.addonCollection.length; i++) {
  //         addons.push({ AddonId: this.addonCollection[i] });
  //       }
  //       this.quoteModel.Addons = addons;
  //       this.quoteModel.Addons = null;
  //     }
  //     if (this.quoteModel.PolicyType == "ThirdParty") {
  //       if (this.quoteModel.ClaimMade == "" && !this.ischeckprevpolicy) {
  //         return false;
  //       }
  //     }
  //     if (this.quoteModel.ClaimMade == "Yes") this.quoteModel.CurrentNCB = "";
  //     this.motorService
  //       .postQuoteDataAndAddons(this.quoteModel)
  //       .subscribe((result: any) => {
  //         if (result && result.status == "done")
  //           sessionStorage.setItem("sessionId", result.Id);

  //         this.router.navigate(["/car-quotation"], {
  //           queryParams: { sessionId: result.Id },
  //         });
  //       });
  //   }
  // }



  // calculateDate($event, type) {
  //   debugger;
  //   console.log("$event1=",$event);
  //   if ($event == null) return;

  //   if (type == 'MembershipExpiryDate') {
  //     this.quoteModel.MembershipExpiry = $event.value._d;
  //   } else {
  //     let d = $event.target.value._i.date;
  //     let mnth = $event.target.value._i.month + 1;
  //     let year = $event.target.value._i.year;
  //     let dtExp = $event.target.value;

  //     let dd = d.toString().length == 1 ? "0" + d : d.toString();
  //     let mm = mnth.toString().length == 1 ? "0" + mnth : mnth.toString();
  //     this.quoteModel.PreviousPolicyExpirDate = year + '-' + mm + '-' + dd;

  //     if (this.quoteModel.CurrentPolicyType == "Expired") {
  //       var dtexp = new Date();
  //       dtexp.setFullYear($event.target.value._i.year, $event.target.value._i.month, $event.target.value._i.date);
  //       //        var ageDifMs = Date.now() - dtexp.getTime();

  //       //var ageDifMs = Date.now() - dtExp.getTime();
  //       var ageDifMs = Date.now() - dtexp.getTime();
  //       if (ageDifMs < 0) {
  //         // Swal.fire('Previous Policy End Date', '', 'error');
  //         // return;
  //       }
  //     }
  //     //this.goClaim();
  //   }
  //   if (this.quoteModel.PolicyType == "ThirdParty") {
  //     debugger;
  //     var dtToday = new Date();
  //     var dtexp = new Date();
  //     dtexp.setFullYear($event.target.value._i.year, $event.target.value._i.month, $event.target.value._i.date);
  //     // if(dtexp<dtToday){
  //     //   this.quoteModel.CurrentPolicyType="Expired";
  //     // }
  //     // else{
  //     //   this.quoteModel.CurrentPolicyType="RollOver";
  //     // }

  //     if (dtexp < dtToday) {
  //       this.quoteModel.CurrentPolicyType = "Expired";
  //     }

  //     if (dtexp < dtToday || this.quoteModel.CurrentPolicyType == "Expired") {
  //       this.isPrevPolDtls = true;
  //       sessionStorage.setItem("PrevPolDtls", "Yes");
  //       this.quoteModel.isPrevPolDtls = "Yes";
  //     }
  //     else {
  //       this.isPrevPolDtls = false;
  //       // sessionStorage.setItem("PrevPolDtls","No");
  //     }
  //     // $("#divPrevPolDtls").show();
  //     $('#divPrevPolDtls').css("display", "block");
  //   }
  //   else {
  //     debugger;
  //     var dtToday = new Date();
  //     var dtexp = new Date();
  //     dtexp.setFullYear($event.target.value._i.year, $event.target.value._i.month, $event.target.value._i.date);

  //     if (dtexp < dtToday) {
  //       this.quoteModel.CurrentPolicyType = "Expired";
  //     }

  //     if (dtexp < dtToday || this.quoteModel.CurrentPolicyType == "Expired") {
  //       this.isPrevPolDtls = true;
  //       sessionStorage.setItem("PrevPolDtls", "Yes");
  //       this.quoteModel.isPrevPolDtls = "Yes";
  //     }
  //     else {
  //       this.isPrevPolDtls = false;
  //       // sessionStorage.setItem("PrevPolDtls","No");
  //     }

  //   }
  // }


  saveQuote() {
    let validate = true;
    if (this.quoteModel.PolicyType == "ThirdParty") {
      if (this.quoteModel.isPrevPolDtls == 'Yes') {
        if (!this.ischeckprevpolicy) {
          $('#divClaim').addClass('active');
          $('#divExpDate').removeClass('active');
          $('#PreviousPolicyEndDate').css("display", "none");
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() - 90);
          this.minDate = currentDate;

          this.maxDate = new Date();
        }
        this.isPrevPolDtls = true;

      }
    }
    else {
      debugger;
      if (this.quoteModel.isPrevPolDtls == 'Yes') {
        if (!this.ischeckprevpolicy) {
          $('#divClaim').addClass('active');
          $('#divExpDate').removeClass('active');
          $('#PreviousPolicyEndDate').css("display", "none");
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() - 90);
          this.minDate = currentDate;

          this.maxDate = new Date();
        }
        this.isPrevPolDtls = true;

      }
    }
   
    if (this.quoteModel.AutomobileMembership == "Yes") {
      if (this.quoteModel.MembershipNo == "" || this.quoteModel.MembershipNo == null) {
        Swal.fire('enter membership no', '', 'error');
        validate = false;
      }
      if (this.quoteModel.MembershipExpiry == "" || this.quoteModel.MembershipExpiry == null) {
        Swal.fire('enter membership expiry date', '', 'error');
        validate = false;
      }

    }
    if (this.quoteModel.PACover == "Yes") {
      this.quoteModel.NoOfPassengers = this.quoteModel.SeatingCapacity;
      if (this.quoteModel.SumInsured == "" || this.quoteModel.SumInsured == null) {
        Swal.fire('select suminsured', '', 'error');
        validate = false;
      }
    }
    if (this.quoteModel.ClaimMade == "" && this.quoteModel.PolicyType != "ThirdParty" && this.quoteModel.CurrentPolicyType != "NEW" && this.quoteModel.CurrentPolicyType != "Expired") {
      if (this.quoteModel.ClaimMade == "" || this.quoteModel.ClaimMade == null) {
        Swal.fire('Select claim in your policy?', '', 'error');
        validate = false;
      }
    }
    if (
          this.quoteModel.CurrentPolicyType != "Expired" &&
          this.quoteModel.ClaimMade == "No"
        ) {
          if (
            this.quoteModel.CurrentNCB == "" ||
            this.quoteModel.CurrentNCB == null
          ) {
            Swal.fire("Select NCB", "", "error");
            validate = false;
          }
        }
    if (this.isNcbDeclear == true) {

      // if (this.quoteModel.CurrentNCB == "" || this.quoteModel.CurrentNCB == null) {
      //   Swal.fire('Select NCB', '', 'error');
      //   validate = false;
      // }
    }
    if (this.quoteModel.CurrentNCB != "" && this.quoteModel.CurrentNCB != "0") {
      if (this.isNcbDeclear == false) {
        Swal.fire('Please checked declare that all the furnished details are correct', '', 'error');
        validate = false;
      }
    }


    debugger;
    if (validate == true) {
      let addons = [];
      if (this.addonCollection.length > 0) {

        for (let i = 0; i < this.addonCollection.length; i++) {
          addons.push({ AddonId: this.addonCollection[i] })
        }
        this.quoteModel.Addons = addons;
      } else {
        this.quoteModel.Addons = null;
      }
      if (this.quoteModel.PolicyType == "ThirdParty") {
        if (this.quoteModel.ClaimMade == '' && !this.ischeckprevpolicy) { return false }
      }
      if (this.quoteModel.ClaimMade == 'Yes') this.quoteModel.CurrentNCB = '';
      this.motorService.postQuoteDataAndAddons(this.quoteModel).subscribe((result: any) => {
        if (result && result.status == "done")
          sessionStorage.setItem("sessionId", result.Id);

        this.router.navigate(['/car-quotation'], { queryParams: { sessionId: result.Id } })
      });
    }
  }
  calculateDate($event, type) {
    debugger;
    console.log("$event=", $event);
    if ($event == null) return;

    if (type == "MembershipExpiryDate") {
      this.quoteModel.MembershipExpiry = $event.value._d;
    } else {
      let d = $event.target.value._i.date;
      let mnth = $event.target.value._i.month + 1;
      let year = $event.target.value._i.year;
      let dtExp = $event.target.value;

      let dd = d.toString().length == 1 ? "0" + d : d.toString();
      let mm = mnth.toString().length == 1 ? "0" + mnth : mnth.toString();
      this.quoteModel.PreviousPolicyExpirDate = year + "-" + mm + "-" + dd;
      sessionStorage.setItem('ChoosenDate',this.quoteModel.PreviousPolicyExpirDate)

      if (this.quoteModel.CurrentPolicyType == "Expired") {
        var dtexp = new Date();
        dtexp.setFullYear(
          $event.target.value._i.year,
          $event.target.value._i.month,
          $event.target.value._i.date
        );

        var ageDifMs = Date.now() - dtexp.getTime();
        if (ageDifMs < 0) {
        }
      }
    }
    if (this.quoteModel.PolicyType == "ThirdParty") {
      debugger;
      var dtToday = new Date();
      var dtexp = new Date();
      dtexp.setFullYear(
        $event.target.value._i.year,
        $event.target.value._i.month,
        $event.target.value._i.date
      );

      if (dtexp < dtToday) {
        this.quoteModel.CurrentPolicyType = "Expired";
        sessionStorage.setItem("CurrentPolicyType", "Expired");
      }

      if (dtexp < dtToday || this.quoteModel.CurrentPolicyType == "Expired") {
        this.isPrevPolDtls = true;
        sessionStorage.setItem("PrevPolDtls", "Yes");
        this.quoteModel.isPrevPolDtls = "Yes";
      } else {
      }
      $("#divPrevPolDtls").css("display", "block");
    } else {
      debugger;
      var dtToday = new Date();
      var dtexp = new Date();
      dtexp.setFullYear(
        $event.target.value._i.year,
        $event.target.value._i.month,
        $event.target.value._i.date
      );

      if (dtexp < dtToday) {
        this.quoteModel.CurrentPolicyType = "Expired";
        sessionStorage.setItem("CurrentPolicyType", "Expired");
      }

      if (dtexp < dtToday || this.quoteModel.CurrentPolicyType == "Expired") {
        this.isPrevPolDtls = true;
        sessionStorage.setItem("PrevPolDtls", "Yes");
        this.quoteModel.isPrevPolDtls = "Yes";
      }
    }
  }
  goDivRegistrationYear(type) {
    debugger;
    if (type == "divClose") {
      $("#myModal").hide();
    } else {
      if (type != "back") {
        this.modalPoup = "divClose";
        $("#divClaim").removeClass("active");
        $("#divExpDate").addClass("active");
      } else {
        if (this.quoteModel.CurrentPolicyType == "NEW") {
          this.router.navigateByUrl("/car-rto?type=NEW&isback=Yes");
        } else {
          this.router.navigateByUrl("/car-rto?type=ROLLOVER&isback=Yes");
        }
      }
    }
  }
  checkPreviousPolicyEndDate(type) {
    //alert(type);
    if (this.quoteModel.PolicyType == "ThirdParty") {
      this.modalPoup = "goClaim";
      if (type == "Yes") {
        this.quoteModel.CurrentPolicyType = "Expired";
        sessionStorage.setItem("PrevPolDtls", "Yes");
        this.quoteModel.isPrevPolDtls = "Yes";
        $("#divPrevPolDtls").css("display", "block");
        this.quoteModel.PreviousPolicyExpirDate = "";
        sessionStorage.setItem("PrevPolDtls", "No");
        this.quoteModel.isPrevPolDtls = "No";
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 90);
        const strDate = new Date();
        strDate.setFullYear(strDate.getFullYear() - 90);
        this.minDate = strDate;
      } else {
        this.ischeckprevpolicy = false;

        this.quoteModel.CurrentPolicyType = "RollOver";
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 90);
        this.minDate = currentDate;
        const endDate = new Date();
        endDate.setDate(currentDate.getDate() + 90);
        this.maxDate = endDate;
        this.isPrevPolDtls = false;
        this.quoteModel.PreviousPolicyExpirDate = "";
        $("#divPrevPolDtls").css("display", "block");
        $("#PreviousPolicyEndDate1").css("display", "none");
      }
    } else {
      this.modalPoup = "goClaim";
      if (type == "Yes") {
        this.quoteModel.CurrentPolicyType = "Expired";
        sessionStorage.setItem("PrevPolDtls", "Yes");
        this.quoteModel.isPrevPolDtls = "Yes";

        $("#divPrevPolDtls").css("display", "block");

        this.quoteModel.PreviousPolicyExpirDate = "";
        sessionStorage.setItem("PrevPolDtls", "No");
        this.quoteModel.isPrevPolDtls = "No";
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 90);
        const strDate = new Date();
        strDate.setFullYear(strDate.getFullYear() - 90);
        this.minDate = strDate;

        this.maxDate = currentDate;
        this.isPrevPolDtls = true;
        this.quoteModel.ClaimMade = "No";
      } else {
        this.ischeckprevpolicy = false;
        this.quoteModel.CurrentPolicyType = "RollOver";

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 90);
        this.minDate = currentDate;
        const endDate = new Date();
        endDate.setDate(currentDate.getDate() + 90);
        this.maxDate = endDate;
        this.isPrevPolDtls = false;
        this.quoteModel.PreviousPolicyExpirDate = "";

        $("#divPrevPolDtls").css("display", "block");
        $("#PreviousPolicyEndDate1").css("display", "none");
      }
    }
  }

  setPrevPolDtlsSession(val) {
    debugger;
    sessionStorage.setItem("PrevPolDtls", val);
    this.quoteModel.isPrevPolDtls = val;
    if (this.quoteModel.CurrentPolicyType == "Expired") {
      if (val == "Yes") {
        this.isPrevPolDtls = true;
        $("#PreviousPolicyEndDate").css("display", "block");
      } else {
        this.quoteModel.ClaimMade = "No";
        this.quoteModel.CurrentNCB = "0";
        this.isNcbDeclear = true;
        this.isPrevPolDtls = false;
        this.quoteModel.CurrentPolicyType = "Expired";
        this.quoteModel.PreviousPolicyExpirDate = null;
        $("#PreviousPolicyEndDate").css("display", "none");
      }
    } else {
      if (val == "Yes") {
        this.isPrevPolDtls = true;
        $("#PreviousPolicyEndDate").css("display", "block");
        this.quoteModel.CurrentPolicyType = "RollOver";
      } else {
        this.quoteModel.ClaimMade = "No";
        this.quoteModel.CurrentNCB = "0";
        this.isNcbDeclear = true;
        this.isPrevPolDtls = false;
        $("#PreviousPolicyEndDate").css("display", "none");
        this.quoteModel.CurrentPolicyType = "Expired";
      }
    }
  }
}
