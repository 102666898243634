import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MotorService } from 'src/app/services/motor.service';
import  {premiumModel, QuotationModel}  from '../../common/Models/twoWheeler'
import Swal from 'sweetalert2';
declare var $: any;
 
@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css']
})
export class CompareComponent implements OnInit {
  iscolumntypecount:number=4;
  quoteModel:QuotationModel=null;
  AddOnList=[];
  CompareList2=[];
  CompareList3=[];
  getCompareList=[];
  @ViewChild('pdfTable',{static:false}) pdfTable: ElementRef;
  @ViewChild("textEmail", { static: false }) textEmail: ElementRef;
  urlCopy:string="";
  isSelected:string="";
  isEmail: boolean = false;
  isSMS: boolean = false;
  LOBCar: boolean = false
  Email : any;
  Mobile : any;

  SessionId:string="";

  constructor(private router: Router,
       private globalService:GlobalService,private motorService:MotorService
      //  private healthMasterService:HealthservicesService,
      //  private healthService:HealthProposalService
       ) {}
      
  ngOnInit() {
    debugger

    let CompareLOB = sessionStorage.getItem('LOB')

    console.log(CompareLOB, 'CompareLOB')

    if(CompareLOB == 'Motor-PrivateCar'){
      debugger
      
      this.LOBCar = true
    }
    this.getCompareList=JSON.parse(localStorage.getItem('compareArr'));
    debugger;
    var vid="";
    //var SessionId="";
    for(let i=0;i<this.getCompareList.length;i++){
      let ProductId=this.getCompareList[i].ProductId;
      if(this.getCompareList[i].SessionId!=null){
        this.SessionId=this.getCompareList[i].SessionId;
      }
      
      if(i==0){
        vid=ProductId;
      }else{
        vid=vid+','+ProductId;
      }
    }

   

    // this.motorService.CompareQuotes(SessionId).subscribe((res: any[]) => { 
    //   debugger;
    //   if (res != null) {       
    //       this.AddOnList=res;          
         
    //   }
    // });
  
  }
  ngAfterViewChecked() {
    // this.getCompareList=JSON.parse(localStorage.getItem('compareArr'));          
    // for(let i=0;i<this.getCompareList[0].MatchedFeatureList.length;i++){
    //   $('.'+this.getCompareList[0].MatchedFeatureList[i].FeatureID).addClass("back_color_");
    // }
  }
  
  savePremium(premium){
    let quoteId=parseInt(localStorage.getItem('quotaionId')) ;  
    premium.QuotionId = quoteId;
  //   this.healthService.postPremiumData(premium).subscribe((res:number)=>{
  //   if(res)   
  //     this.router.navigate(['/proposal'], { queryParams: { id: res } })    
  // });
}

  filterCompare(type){
    if(JSON.parse(localStorage.getItem('compareArr')) !=null){
      console.log(JSON.parse(localStorage.getItem('compareArr')));
      return JSON.parse(localStorage.getItem('compareArr'));
    }
  }
  
  getAddon(premium:premiumModel,AddOnId){
    debugger
    if(premium.Addons.length>0){
      for(let i=0;i<premium.Addons.length;i++){
        let addon=premium.Addons[i];
        if(addon.AddOnId==AddOnId){
          return addon.AddOnPrice;
        }else{
          return 0;
        }      
       
      }
    }else return 0;
  }
  filterItemsOfType(type){
    if(this.AddOnList !=null){
      return this.AddOnList.filter(x => x.Id != type);
    }
    
  }
  calPremiumToMonth(Premium){   
   var amount=this.numberToText(Math.round(Premium/12));
   return amount;
  }
  numberToText(SumInsured){
    var Amount=SumInsured.toString();
    var lastThree = Amount.substring(Amount.length-3);
    var otherNumbers = Amount.substring(0,Amount.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;

    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  }

  downloadAsPDF() {
    
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      //const pdf=new jsPDF();
      let pdf  = new jsPDF()
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
  }

  shareQuote(){ 
    debugger; 
    if(this.isSelected==""){
      Swal.fire('Plz. Select any one share option', '', 'error');
      return;
    }
    let textEmail=this.textEmail.nativeElement.value;
    if(textEmail==""){
      Swal.fire('Plz. enter Email Id', '', 'error');
      return;
    }else{   
      var quoteModel = new QuotationModel;   
      quoteModel.ExField1=textEmail;
      // quoteModel.ExField2=window.location.href;
      quoteModel.ExField2=window.location.href.replace('compare','motor-quotation?sessionId='+this.SessionId);
      $("#removeContent").empty();
      var data = document.getElementById('contentToConvert');
      this.motorService.CompareQuotesMailer([quoteModel],data.outerHTML).subscribe((result:any)=>{
        if(result>0){
        $("#shareQuote").modal("hide");
          Swal.fire('Private Car quotation details successfully send.', '', 'success');
          window.location.reload();
        }
        else{
          Swal.fire('Private Car quotation details not successfully send.', '', 'error');
        }
                  
      });
    }
  }
  
  
  copyUrl(type) {
    if (type == 'Email') {
      this.urlCopy = '';
      this.isEmail=true;
      this.isSMS=false;
    }
    if (type == 'SMS') {
      this.urlCopy = '';
      this.isSMS=true;
      this.isEmail=false;
    }
    if (type == 'Link') {
      this.urlCopy = window.location.href;
      var $temp = $("<input>");
      var $url = $(location).attr('href');
      $("body").append($temp);
      $temp.val($url).select();
      document.execCommand("copy");
      $temp.remove();
      //$("p").text("URL copied!");  
      Swal.fire('URL copied!', '', 'success');

      $("#shareQuote").modal("hide");
    }
    if (type == 'copy') {
      this.urlCopy = window.location.href;
    }

    //alert(this.urlCopy);
  }
  
}
