"use strict";
//import {  Headers} from '@angular/common/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
export const sep = "/";
export const version: string = "22.2.2";
//export const apiUrl='http://adeptinfo.co.in:3035/api/';
export const apiUrl = "http://localhost:11275/api/";

export class AppSettings {
  // public static DomainUrlMotorCarInspection = "https://uat.bimaraja.com:4521/"; //uat
  // public static DomainUrl = "https://uat.bimaraja.com:4541"; //uat;
  // public static DomainUrlHEALTH = "https://uat.bimaraja.com:3452"; //uat;
  // public static API_ENDPOINTHEALTH = "https://uat.bimaraja.com:3452/api"; //uat
  // public static API_ENDPOINT = "https://uat.bimaraja.com:4541/api"; //uat
  // public static API_ENDPOINT_CKYC = "https://uat.bimaraja.com:6631/api"; // CKYC UAT

  public static DomainUrlMotorCarInspection = 'https://bimaraja.com:7010/';//prod
  public static DomainUrl = 'https://bimaraja.com:7010';//prod;
  public static DomainUrlHEALTH = 'https://www.riskcareinsure.com:8089';//prod;
  public static API_ENDPOINTHEALTH = 'https://www.riskcareinsure.com:8089/api';//prod
  public static API_ENDPOINT = 'https://bimaraja.com:7010/api';//prod
  public static API_ENDPOINT_CKYC = 'https://bimaraja.com:8383/api'; // CKYC prod
  public static API_ENDPOINT2 = 'https://bimaraja.com:7319';//PROD
  // public static API_ENDPOINT2 = "https://uat.bimaraja.com:4544"; //UAT
  public static DomainUrlCare = "https://careuat.bimaraja.com"; //uat

  //   public static DomainUrl='http://localhost:11275';//devlopment
  // public static DomainUrlHEALTH='http://localhost:11274';//local;
  //  public static API_ENDPOINT='http://localhost:11275/api'; //devlopment
  // public static API_ENDPOINT_CKYC='https://localhost:44301/api'; // CKYC devlopment
  // public static API_ENDPOINTHEALTH ='http://localhost:11274/api';//local
  //public static API_ENDPOINT='http://adeptinfo.co.in:3407/api/v1.0';
}

export class CommonClass {
  public static getHttpHeader(): HttpHeaders {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return headers;
  }
}
