import { User, UserAuthentication } from './../../../common/Models/userDetail';
import { Component, OnInit,Input, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import{premiumModel,premiumData,quotePlan} from '../../../common/Models/premium.models';
import { HealthservicesService } from '../../../services/healthservices.service';
import { HealthIndexComponent } from '../health-index/health-index.component';
import { BuildplanComponent } from '../buildplan/buildplan.component';
import{HealthProposalService} from '../../../services/proposal.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {ErrorHandle} from '../../../common/models/ErrorHandle';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {token} from '../../../common/Models/token'
import { first } from 'rxjs/operators';
import { GlobalService } from 'src/app/global.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

declare var $: any;
@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.css']
})
export class QuotationComponent implements OnInit {
  @Input() premium;  
   @Input() show;  
   favlistcount: number = 0;
   @Output() newItemEvent: EventEmitter<any> = new EventEmitter();
  compareArr:any= [];
  premiumData:premiumModel;
  registerForm: FormGroup;
  loginForm:FormGroup;
    loading = false;
    submitted = false;
    showLoad : boolean = true;
 isLogin:boolean;
 isRegister:boolean;
 ErrorHandle:ErrorHandle;
 token:token;
 loginUser:User[];
 UserAuthentication: UserAuthentication;
  constructor(private router: Router,
    private healthService:HealthProposalService,
    private formBuilder: FormBuilder,
    private authenticationService:AuthenticationService,
    private globalService:GlobalService) { 
      
    }
    
  ngOnInit() { 

    // setTimeout(() => {
    //   this.showLoad = false;
    // }, 8000);
    
    this.bindPremium();   
    $("#loginmodal").click(function () {
      $("#showLoginmodal").modal('show');
    }); 
  
 
  }

  bindPremium(){  
    debugger;
   if(this.premium.MatchedFeatureList==null) this.premium.MatchedFeatureList=[];
   if(this.premium.NotMatchedFeatureList==null) this.premium.NotMatchedFeatureList=[];
   if(this.premium.TotalFeatureList==null) this.premium.TotalFeatureList=[];
   this.premiumData=new premiumModel();
   this.premiumData.planId=this.premium.PlanTypeId;
   this.premiumData.policyType=this.premium.PolicyType;
   this.premiumData.planName= this.premium.PlanType;
   this.premiumData.img=this.premium.ProductImage;
   //this.premiumData.cover=(this.premium.SumInsured/100000)+'Lakh';
   this.premiumData.cashlessHospitals=this.premium.HospitalCnt;
   this.premiumData.premiumForMonth=Math.round( this.premium.Premium/12);
   this.premiumData.annualPremium=this.premium.Premium;
   this.premiumData.ProductId=this.premium.ProductId;
   this.premiumData.productname=this.premium.ProductName;
   this.premiumData.companyName=this.premium.CompanyName;
   this.numberToText(this.premium.SumInsured.toString());

   sessionStorage.removeItem("compareArr");
   this.globalService.compareArr=[];
 }
 numberToText(SumInsured){
   var lastThree = SumInsured.substring(SumInsured.length-3);
   var otherNumbers = SumInsured.substring(0,SumInsured.length-3);
   if(otherNumbers != '')
       lastThree = ',' + lastThree;

   this.premiumData.cover=otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
 }
 viewFeature(plan){
   //alert(plan);
  // $("#matchviewPlan-"+plan).click(function () {
     $("#viewPlanModal-"+plan).modal('show');
  // });
 }
 favourite(plan:string){
   //alert(plan);  
   if(sessionStorage.getItem('userName') ==null){
     $("#showLoginmodal").modal("show");
     let interval = setInterval(() => {
       if(this.globalService.isLogin==true) {       
           this.setFavourateData(plan);
            clearInterval(interval);
       }
     }, 1000);
   }else{      
     this.setFavourateData(plan);
   }    
  }
  setFavourateData(plan:string){
   let checkChecked=false;    
   this.globalService.favouriteList.forEach((element,index)=>{
     if(element.planId==plan){
       checkChecked=true;
       $("#favourite_"+plan).removeClass("fa fa-heart").addClass("fa fa-heart-o");
       this.globalService.favouriteList.splice(index,1);
     } 
  }); 
   if(checkChecked==false){
     $("#favourite_"+plan).removeClass("fa fa-heart-o").addClass("fa fa-heart");  
     this.globalService.favouriteList.push({planId:this.premiumData.planId,image:this.premiumData.img,cover:this.premiumData.cover,premiumForMonth:this.premiumData.premiumForMonth,annualPremium:this.premiumData.annualPremium,planName:this.premiumData.planName});
   } 
   this.favlistcount=this.globalService.favouriteList.length;
  
   this.premium.UserId=sessionStorage.getItem('UserId');
   let quoteId=parseInt(sessionStorage.getItem('quotaionId')) ;  
   this.premium.QuotionId = quoteId;
   this.healthService.postfavouritePremiumData(this.premium).subscribe((res:number)=>{
     if(res>0){
       this.newItemEvent.emit(this.favlistcount);
     }
   });
  }
 planCompare(quotepremium, isChecked: boolean){
   //alert(plan);    
   debugger
     if(isChecked) {
       if(this.globalService.compareArr.length < 3){
       this.globalService.compareArr.push(quotepremium);
             $("#compareDiv").show();
       }else{
         document.getElementById("compare_"+quotepremium.PlanTypeId).click();
         $("#compareDiv").show();
         Swal.fire('Max three company compare yet time. ', '', 'error');
       }
     } else {
       this.globalService.compareArr.forEach((element,index)=>{
         if(element.PlanTypeId==quotepremium.PlanTypeId){
           this.globalService.compareArr.splice(index,1);
           //this.globalService.compareArr.removeAt(index)
           //delete this.globalService.compareArr[index];
         } 
      });
       
     }
  }

  filterCompare(type){
   if(this.globalService.compareArr !=null){
     //console.log(this.globalService.compareArr);
     return this.globalService.compareArr;
   }
 }
 deletePalnInCom(planId){    
   document.getElementById("compare_"+planId).click();
   
 }
 closeCompare(){    
   $("#compareDiv").hide();
   this.globalService.compareArr.forEach(function (item) {
     debugger;
     document.getElementById("compare_"+item.PlanTypeId).click();      
   });
   sessionStorage.removeItem("compareArr");
   this.globalService.compareArr=[];
 }
//  redirectComparePage(){
//   debugger
//    if(sessionStorage.getItem('userName') ==null){
//      this.globalService.isLogin=false;
//      $("#showLoginmodal").modal("show");
//      let interval = setInterval(() => {
//        if(this.globalService.isLogin==true) {       
//            sessionStorage.setItem('compareArr', JSON.stringify(this.globalService.compareArr))
//            const url = this.router.serializeUrl(this.router.createUrlTree(['/compare']));
//            window.open(url, '_blank'); 
//             clearInterval(interval);
//        }
//      }, 1000);
//    }else{      
//      sessionStorage.setItem('compareArr', JSON.stringify(this.globalService.compareArr))
//      const url = this.router.serializeUrl(this.router.createUrlTree(['/compare']));
//      window.open(url, '_blank'); 
//    }
   
//  }
redirectComparePage() {
  if (this.globalService.compareArr.length > 1) {
    localStorage.setItem('compareArr', JSON.stringify(this.globalService.compareArr))
    const url = this.router.serializeUrl(this.router.createUrlTree(['/Compare-Health']));
    window.open(url, '_blank');
  } else {
    Swal.fire('select minimum of two quotes. ', '', 'error');
  }
  
}
 checkUserlogin(){
   if(sessionStorage.getItem('userName') ==null){
     this.globalService.isLogin=false;
     $("#showLoginmodal").modal("show");
     let interval = setInterval(() => {
       if(this.globalService.isLogin==true) {       
           this.savePremium('proposal');
            clearInterval(interval);
       }
     }, 1000);
   }else{      
     this.savePremium('proposal');
   }
 }
 savePremium(id){debugger;
   let quoteId=parseInt(sessionStorage.getItem('quotaionId')) ;  
   this.premium.QuotionId = quoteId;
   this.premium.UserId = sessionStorage.getItem('UserId');
   this.healthService.postPremiumData(this.premium).subscribe((res:number)=>{
   if(res)
   if(id=='proposal'){
     this.router.navigate(['/healthproposal'], { queryParams: { id: res } })
   }else{ 
     sessionStorage.setItem('companyId',this.premium.CompanyId.toString());
     sessionStorage.setItem('productId',this.premiumData.ProductId.toString());
     sessionStorage.setItem('planId',this.premiumData.planId.toString());
     sessionStorage.setItem('viewplanImg',this.premiumData.img);
     sessionStorage.setItem('viewplanCover',this.premiumData.cover);
     sessionStorage.setItem('viewplanPremiumForMonth',this.premiumData.premiumForMonth.toString());
     sessionStorage.setItem('viewplanAnnualPremium',this.premiumData.annualPremium.toString());
     sessionStorage.setItem('planName',this.premium.PlanType);
     sessionStorage.setItem('productname',this.premium.ProductName);
     //let viewPlanUrl=this.router.navigate(['/viewplan'], { queryParams: { id: res } })      
     const url = this.router.serializeUrl(this.router.createUrlTree(['/viewplan'], { queryParams: { id: res } }));
       window.open(url, '_blank'); 
   }    
   },
   ((err:ErrorHandle) => {
     this.ErrorHandle=err;       
      if(this.ErrorHandle.statusText=="Bad Request"){
        Swal.fire(this.ErrorHandle.statusText, '', 'error');
        
      }
 })
   ); 
   //, err => console.log(err)
   // );
}

goSelectedPlan(pageType){
 if(sessionStorage.getItem('userName') ==null){
   this.globalService.isLogin=false;
   $("#showLoginmodal").modal("show");
   let interval = setInterval(() => {
     if(this.globalService.isLogin==true) {       
         this.savePremium('viewPlan');
          clearInterval(interval);
     }
   }, 1000);
 }else{      
   this.savePremium('viewPlan');
 }
}
goBack(divid){
 // this.divAddress=false;
  this.router.navigateByUrl('/buildplan');
}
}
