import { PrivacyPolicyComponent } from './common/privacy-policy/privacy-policy.component';
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './common/account/login/login.component';
import { ProfileComponent } from './common/profile/profile.component';
import { QuotesComponent } from './common/quotes/quotes.component';
// import { CarInsuranceComponent } from './motor-insurance/car-insurance/car-insurance.component';
import { TwoWheelerComponent } from './motor-insurance/two-wheeler/two-wheeler.component';
import { TwoWheelerQuotationComponent } from './motor-insurance/two-wheeler-quotation/two-wheeler-quotation.component';
import { RtoComponent } from './motor-insurance/rto/rto.component';
import { TwowheelerProposalComponent } from './motor-insurance/twowheeler-proposal/twowheeler-proposal.component';
import { AddonsComponent } from './motor-insurance/addons/addons.component';
import { CompareComponent } from './motor-insurance/compare/compare.component';
import { ViewplandetailsComponent } from './motor-insurance/viewplandetails/viewplandetails.component';
import { MotorQuotationComponent } from './motor-insurance/motor-quotation/motor-quotation.component';
import { ErrorPageComponent } from './motor-insurance/error-page/error-page.component';
import { HomeComponent } from './common/home/home.component';
import { CarInsuranceComponent } from './Car/car-insurance/car-insurance.component';
import { CarCoverageComponent } from './Car/car-coverage/car-coverage.component';
import { CarQuotationComponent } from './Car/car-quotation/car-quotation.component';
import { CarProposalComponent } from './Car/car-proposal/car-proposal.component';
import { BikeInsuranceComponent } from './TwoWheeler/bike-insurance/bike-insurance.component';
import { BikeCoverageComponent } from './TwoWheeler/bike-coverage/bike-coverage.component';
import { BikeQuotationComponent } from './TwoWheeler/bike-quotation/bike-quotation.component';
import { BikeProposalComponent } from './TwoWheeler/bike-proposal/bike-proposal.component';
import { AboutComponent } from './common/about/about.component';
import { ContactComponent } from './common/contact/contact.component';
import { CareersComponent } from './common/careers/careers.component';
import { ClaimFormComponent } from './common/claim-form/claim-form.component';
import { DisclaimerComponent } from './common/disclaimer/disclaimer.component';
import { DndPolicyComponent } from './common/dnd-policy/dnd-policy.component';
import { InsuranceNewsComponent } from './common/insurance-news/insurance-news.component';
import { ManagementTeamComponent } from './common/management-team/management-team.component';
import { ProductWordingComponent } from './common/product-wording/product-wording.component';
import { SitemapComponent } from './common/sitemap/sitemap.component';
import { ArogyaSanjeevniComponent } from './Health/arogya-sanjeevni/arogya-sanjeevni.component';
import { CoronaKavachComponent } from './Health/corona-kavach/corona-kavach.component';
import { CriticalIllnessComponent } from './Health/critical-illness/critical-illness.component';
import { FamilyHealthInsuranceComponent } from './Health/family-health-insurance/family-health-insurance.component';
import { HealthInsuranceComponent } from './Health/health-insurance/health-insurance.component';
import { PersonalAccidentComponent } from './Health/personal-accident/personal-accident.component';
import { SeniorCitizenComponent } from './Health/senior-citizen/senior-citizen.component';
import { SuperTopupComponent } from './Health/super-topup/super-topup.component';
import { CarRtoComponent } from './Car/car-rto/car-rto.component';
import { BikeRtoComponent } from './TwoWheeler/bike-rto/bike-rto.component';
import { HealthIndexComponent } from './Health/health-insurance/health-index/health-index.component';
import { BuildplanComponent } from './Health/health-insurance/buildplan/buildplan.component';
import { QuotationComponent } from './Health/health-insurance/quotation/quotation.component';
import { QuotationsComponent } from './Health/health-insurance/quotations/quotations.component';
import { HealthProposalComponent } from './Health/health-insurance/health-proposal/health-proposal.component';
import { HealthpaymentsuccessComponent } from './Health/health-insurance/healthpaymentsuccess/healthpaymentsuccess.component';
import { HealthpaymentfailedComponent } from './Health/health-insurance/healthpaymentfailed/healthpaymentfailed.component';
import { BikeInsuranceRenewalComponent } from './RenewPolicy/bike-insurance-renewal/bike-insurance-renewal.component';
import { CarInsuranceRenewalComponent } from './RenewPolicy/car-insurance-renewal/car-insurance-renewal.component';
import { HealthInsuranceRenewalComponent } from './RenewPolicy/health-insurance-renewal/health-insurance-renewal.component';
import { TermLifeInsuranceComponent } from './LifeInsurance/term-life-insurance/term-life-insurance.component';
import { PrivateCarTwoWheelerComponent } from './Knowledge Center/private-car-two-wheeler/private-car-two-wheeler.component';
import { IndividualFamilyHealthPlanComponent } from './Knowledge Center/individual-family-health-plan/individual-family-health-plan.component';
import { IrdiaNewsComponent } from './Knowledge Center/irdia-news/irdia-news.component';
import { TermLifeComponent } from './Knowledge Center/term-life/term-life.component';
import { TravelComponent } from './Knowledge Center/travel/travel.component';
import { OtherProductComponent } from './Knowledge Center/other-product/other-product.component';
import { RegistrationportalComponent } from './common/account/registrationportal/registrationportal.component';
import { DomesticTravelInsuranceComponent } from './Travel Insurance/domestic-travel-insurance/domestic-travel-insurance.component';
import { InternationalTravelInsuranceComponent } from './Travel Insurance/international-travel-insurance/international-travel-insurance.component';
import { StudentTravelInsuranceComponent } from './Travel Insurance/student-travel-insurance/student-travel-insurance.component';
import { SeniorCitizenTravelInsuranceComponent } from './Travel Insurance/senior-citizen-travel-insurance/senior-citizen-travel-insurance.component';
import { CommercialVehicleInsuranceComponent } from './commercial-vehicle-insurance/commercial-vehicle-insurance.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { LeftpanelComponent } from './common/dashboard/leftpanel/leftpanel.component';
import { ViewplanComponent } from './Health/viewplan/viewplan.component';
import { ForgetpasswordComponent } from './common/account/forgetpassword/forgetpassword.component';
import { MaintenanceComponent } from './common/maintenance/maintenance.component';
import { RegisterPospComponent } from './POSP/register-posp/register-posp.component';
import { AddPosDetailsComponent } from './POSP/add-pos-details/add-pos-details.component';
import { AddDetailsComponent } from './POSP/add-details/add-details.component';
import { AcceptedPosComponent } from './POSP/accepted-pos/accepted-pos.component';
import { RejectedPosComponent } from './POSP/rejected-pos/rejected-pos.component';
import { TotalPosComponent } from './POSP/total-pos/total-pos.component';
import { DashboardPOSPComponent } from './POSP/dashboard-posp/dashboard-posp.component';
import { OnholdPospComponent } from './POSP/onhold-posp/onhold-posp.component'
import { PospPendingRequestComponent } from './POSP/posp-pending-request/posp-pending-request.component';
import { PospLandingPageComponent } from './POSP/posp-landing-page/posp-landing-page.component';
import { CustomerPageComponent } from './customer-page/customer-page.component';
import { CompareHealthComponent } from './Health/health-insurance/compare-health/compare-health.component';
import { MyInspectionComponent } from './common/my-inspection/my-inspection.component';
import { MyQuotationComponent } from './my-quotation/my-quotation.component';
import { MyPolicyComponent } from './common/my-policy/my-policy.component';
// import {AllpoliciesComponent} from './'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'car-insurance',
    component: CarInsuranceComponent
  },
  {
    path: 'two-wheeler',
    component: TwoWheelerComponent
  },
  {
    path: 'two-wheeler-quotation',
    component: TwoWheelerQuotationComponent
  },
  {
    path: 'motor-quotation',
    component: MotorQuotationComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'quotes',
    component: QuotesComponent
  },
  // {
  //   path: 'privacyPolicy',
  //   component: PrivacyPolicyComponent
  // }, 
  {
    path: 'rto-registration',
    component: RtoComponent
  }
  ,
  {
    path: 'twowheeler-proposal',
    component: TwowheelerProposalComponent
  },
  // {
  //   path: 'buildplan',
  //   component: AddonsComponent
  // }, 
  {
    path: 'compare',
    component: CompareComponent
  },
  {
    path: 'viewplandetails',
    component: ViewplandetailsComponent
  },
  {
    path: 'error-page',
    component: ErrorPageComponent
  },
  {
    path: 'car-coverage',
    component: CarCoverageComponent
  },
  {
    path: 'car-quotation',
    component: CarQuotationComponent
  },
  {
    path: 'car-proposal',
    component: CarProposalComponent
  },
  {
    path: 'car-rto',
    component: CarRtoComponent
  },
  {
    path: 'bike-insurance',
    component: BikeInsuranceComponent
  },
  {
    path: 'bike-coverage',
    component: BikeCoverageComponent
  },
  {
    path: 'bike-quotation',
    component: BikeQuotationComponent
  },
  {
    path: 'bike-proposal',
    component: BikeProposalComponent
  },
  {
    path: 'bike-rto',
    component: BikeRtoComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'careers',
    component: CareersComponent
  },
  {
    path: 'claimform',
    component: ClaimFormComponent
  },
  {
    path: 'disclaimer',
    component: DisclaimerComponent
  },
  {
    path: 'privacyPolicy',
    component: DndPolicyComponent
  },
  {
    path: 'insurancenews',
    component: InsuranceNewsComponent
  },
  {
    path: 'managementteam',
    component: ManagementTeamComponent
  },
  {
    path: 'productwording',
    component: ProductWordingComponent
  },
  {
    path: 'sitemap',
    component: SitemapComponent
  },
  {
    path: 'arogyasanjeevni',
    component: ArogyaSanjeevniComponent
  },
  {
    path: 'coronakavach',
    component: CoronaKavachComponent
  },
  {
    path: 'criticalillness',
    component: CriticalIllnessComponent
  },
  {
    path: 'familyhealthinsurance',
    component: FamilyHealthInsuranceComponent
  },
  {
    path: 'healthinsurance',
    component: HealthInsuranceComponent
  },
  {
    path: 'healthindex',
    component: HealthIndexComponent
  },
  {
    path: 'buildplan',
    component: BuildplanComponent
  },
  {
    path: 'healthquotation',
    component: QuotationsComponent
  },
  {
    path: 'healthproposal',
    component: HealthProposalComponent
  },
  {
    path: 'Healthpaymentsuccess',
    component: HealthpaymentsuccessComponent
  },
  {
    path: 'Healthpaymentfailed',
    component: HealthpaymentfailedComponent
  },
  {
    path: 'personalaccident',
    component: PersonalAccidentComponent
  },
  {
    path: 'viewplan',
    component: ViewplanComponent
  },
  {
    path: 'seniorcitizen',
    component: SeniorCitizenComponent
  },
  {
    path: 'supertopup',
    component: SuperTopupComponent
  },
  {
    path: 'carinsurancerenewal',
    component: CarInsuranceRenewalComponent
  },
  {
    path: 'bikeinsurancerenewal',
    component: BikeInsuranceRenewalComponent
  },
  {
    path: 'healthinsurancerenewal',
    component: HealthInsuranceRenewalComponent
  },
  {
    path: 'termlifeinsurance',
    component: TermLifeInsuranceComponent
  },
  {
    path: 'privatecartwowheelernews',
    component: PrivateCarTwoWheelerComponent
  },
  {
    path: 'individualfamilyhealthplannews',
    component: IndividualFamilyHealthPlanComponent
  },
  {
    path: 'irdianews',
    component: IrdiaNewsComponent
  },
  {
    path: 'termlifenews',
    component: TermLifeComponent
  },
  {
    path: 'travelnews',
    component: TravelComponent
  },
  {
    path: 'otherproductnews',
    component: OtherProductComponent
  },
  {
    path: 'registration',
    component: RegistrationportalComponent
  },
  {
    path: 'domestictravelinsurance',
    component: DomesticTravelInsuranceComponent
  },
  {
    path: 'internationaltravelinsurance',
    component: InternationalTravelInsuranceComponent
  },
  {
    path: 'studenttravelinsurance',
    component: StudentTravelInsuranceComponent
  },
  {
    path: 'seniorcitizentravelinsurance',
    component: SeniorCitizenTravelInsuranceComponent
  },
  {
    path: 'commercialvehicleinsurance',
    component: CommercialVehicleInsuranceComponent
  },
  // {
  //   path:'profilepolicy',
  //   component: AllpoliciesComponent
  // },
  // {
  //   path:'profilepayment',
  //   component: PaymentComponent
  // },
  {
    path: 'profiledashboard',
    component: DashboardComponent
  },
  {
    path: 'leftpanel',
    component: LeftpanelComponent
  },
  {
    path: 'forgetpassword',
    component: ForgetpasswordComponent
  },
  {
    path: 'my-inspection',
    component: MyInspectionComponent
  },
  {
    path: 'my-quotation',
    component: MyQuotationComponent
  },
  {
    path: 'my-policy',
    component: MyPolicyComponent
  },
  {
    path: 'Maintenance',
    component: MaintenanceComponent
  },
  { path: 'register-posp', component: RegisterPospComponent },
  { path: 'add-details', component: AddDetailsComponent },
  { path: 'pos-details', component: AddPosDetailsComponent },
  { path: 'accepted-posp', component: AcceptedPosComponent },
  { path: 'rejected-posp', component: RejectedPosComponent },
  { path: 'total-posp', component: TotalPosComponent },
  { path: 'onhold-posp', component: OnholdPospComponent },
  { path: 'pending-posp', component: PospPendingRequestComponent },
  {
    path: 'posp-Page',
    component: PospLandingPageComponent
  },
  { path: 'customer-page', component: CustomerPageComponent },
  {
    path: 'Compare-Health',
    component: CompareHealthComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
