import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EncrDecrService } from 'src/app/services/EncrDecrService';
import { HealthservicesService } from 'src/app/services/healthservices.service';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-my-policy',
  templateUrl: './my-policy.component.html',
  styleUrls: ['./my-policy.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
    ,
     { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})


export class MyPolicyComponent implements OnInit {
  date:any=[];
  serializedDate:any;
  UserId:string;
  Email:any;
  fromDate:any='';
  toDate:any='';
  policyNumber:string='';
  policies=[];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
 
  constructor(
    private healthMasterService: HealthservicesService,
    private router: Router,
    private globalService: GlobalService,
    private authService: AuthenticationService,
    private EncrDecr: EncrDecrService
  ) {
    if (sessionStorage.getItem('MobileNo') != null) {
      this.globalService.lendingpage = true;
      this.UserId = sessionStorage.getItem('MobileNo');
    } else {
      this.router.navigate(['/login']);
    }
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }
   
  ngOnInit() {
   debugger;
   this.globalService.lendingpage = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
    $("#dashboard_left_panel").click(function(){
      $(".left_panel").toggleClass("show_left_panel");
    });

    if(sessionStorage.getItem('toDate') !=null){
      debugger
      this.policyNumber=sessionStorage.getItem('policyNumber');
      this.fromDate=new Date(sessionStorage.getItem('fromDate'));//sessionStorage.getItem('fromDate')
      this.toDate=new Date(sessionStorage.getItem('toDate'));//sessionStorage.getItem('toDate')
    }
    debugger
    this.healthMasterService.GetPolicies(this.UserId,this.policyNumber,this.fromDate,this.toDate).subscribe((res:any)=>{
      this.policies=res;
      this.dtTrigger.next(); 
      });
    
  }

  // searchPolicy(){  
  //   debugger 
    
  //     sessionStorage.setItem('policyNumber',this.policyNumber.toString()); 
  //     sessionStorage.setItem('fromDate',this.fromDate);
  //     sessionStorage.setItem('toDate',this.toDate); 
    
  //   this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
  //     this.router.navigate(['/mypolicy']);
  //     });
    
  // }

  buttonInRowClick(id,QuotionId){
    localStorage.setItem('quotaionId',QuotionId.toString()); 
    this.router.navigate(['/proposal'], { queryParams: { id: id } })
  }

}
