import { ActivatedRoute, Router } from '@angular/router';
import { Component,ViewChild , OnInit, HostListener} from '@angular/core';
import {GlobalService} from './global.service'
import {HeaderComponent} from './common/header/header.component';
import {AuthenticationService} from 'src/app/services/authentication.service';
import { User ,UserAuthentication} from './common/Models/userDetail';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {token} from './common/Models/token';
import {ErrorHandle} from './common/models/ErrorHandle';
import Swal from 'sweetalert2';
import { EncrDecrService } from 'src/app/services/EncrDecrService';
import { HttpParams } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// import { SessionService } from './services/session.service';

declare var $: any;
@Component({
  selector: 'app-root',

  templateUrl:  './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit  {
//   isShow: boolean;
//   topPosToStartShowing = 100;
  
//   @HostListener('window:scroll')
//   checkScroll() {
      
//     // window의 scroll top
//     // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

//     const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

//     //console.log('[scroll]', scrollPosition);
    
//     if (scrollPosition >= this.topPosToStartShowing) {
//       this.isShow = true;
//     } else {
//       this.isShow = false;
//     }
//   }

//   // TODO: Cross browsing
//   gotoTop() {
//     window.scroll({ 
//       top: 0, 
//       left: 0, 
//       behavior: 'smooth' 
//     });
//   }
  
//   title = '';
//   isLogin:boolean; 
//   @ViewChild(HeaderComponent,{static:false}) headerComponent : HeaderComponent;
//   user:any;
//   registerForm: FormGroup;
//   loginForm:FormGroup;
//     loading = false;
//     submitted = false;  
//     isLogedUser:boolean=false;

//  isRegister:boolean;
 ErrorHandle:ErrorHandle;
 token:token;
//  loginUser:User[];
//  UserAuthentication: UserAuthentication;
//  logedUser:string=null;
FullName:any;
userEmail:any;
userMobile:any;
isLogin:any;
  constructor( public globalService:GlobalService,private authService:AuthenticationService,private EncrDecr: EncrDecrService,
    private formBuilder: FormBuilder,private router:Router,private routerActive:ActivatedRoute)
    {
           
   
    //   this.globalService.showfooter=true;
    //   debugger;
    //   sessionStorage.setItem('userName','ONLINEHEALTH');
    //   sessionStorage.setItem('email','onlinehealth@gmail.com');
    //   sessionStorage.setItem('mobile','9999999999');
      let vUserAuthentication = new UserAuthentication();
      vUserAuthentication.UserName='onlinehealth@gmail.com';
      vUserAuthentication.Password='123456';
      this.authService.getAuthentication(vUserAuthentication).subscribe((data: token)=>{          
        this.token=data;
        if(this.token.access_token !==''){
            // this.loading = false;
            sessionStorage.setItem('access_token',this.token.access_token);
            //this.router.navigate(["/dashboard"]);
            // this.GetUserDetails('onlinehealth@gmail.com');
        }
        else{
            // this.loading = false;
            Swal.fire('Some error.', '', 'error');
        }         
        
      },((err:ErrorHandle) => {
        this.ErrorHandle=err;       
         if(this.ErrorHandle.statusText=="Bad Request"){
           Swal.fire('Userid and password are incorrect.', '', 'error');
         }
    }));
    // if(sessionStorage.getItem('userName')==null){      
    //   sessionStorage.removeItem('userName');
    //   sessionStorage.removeItem('email');
    //   sessionStorage.removeItem('mobile'); 
    //   this.globalService.isLogin=false; 
    //   this.isLogin=false;
    // }else{
    //   this.globalService.isLogin=true;
    //   this.isLogin=true;
    //   this.logedUser='Welcome,'+ sessionStorage.getItem('userName');
    // } 
    // this.isLogin=true;
    // this.isRegister=false; 
    
  }

  ngOnInit() {

    if(sessionStorage.getItem('EmailId')!=null || sessionStorage.getItem('MobileNo')!=null){     
      this.FullName=sessionStorage.getItem('FullName');
      this.userEmail=sessionStorage.getItem('EmailId');
      this.userMobile=sessionStorage.getItem('MobileNo');
      this.isLogin =  sessionStorage.getItem("isLogin");
    }
    this.router.events.subscribe(
      (Data: any) => {
        // alert("test");
        
        if(sessionStorage.getItem('EmailId')!=null || sessionStorage.getItem('MobileNo')!=null){     
          this.FullName=sessionStorage.getItem('FullName');
          this.userEmail=sessionStorage.getItem('EmailId');
          this.userMobile=sessionStorage.getItem('MobileNo');
          this.isLogin =  sessionStorage.getItem("isLogin");
        }
        // this.sessionService.getContentData().subscribe((data: any) => {
        //   console.log(data);
        // });
      });
  // if(sessionStorage.getItem('email')!=null){
  //   this.authService.geUser(sessionStorage.getItem('email')).subscribe((res:User)=>{
  //     this.user=res[0].UserName;  
          
  //     debugger;
  // });
 }

 
 userLogout(){
  sessionStorage.clear();
  this.FullName='';
  this.userEmail='';
  this.userMobile='';
  this.isLogin = '';
  this.router.navigate(['']);

  //window.location.href="https://bimaraja.com";
}
}
//   this.registerForm = this.formBuilder.group({      
//     RoleId: new FormControl(4),
//     UserName: new FormControl(null, Validators.required),
//     Email: new FormControl(null, [Validators.required, Validators.email]),
//     Mobile: new FormControl(null, [Validators.required, Validators.pattern("[0-9]{10}")]),
//     Password: new FormControl(null, [Validators.required, Validators.minLength(6)])
// });
//   this.setUserCategoryValidators();
//   }
//   showLogin(){
//     if(this.globalService.isLogin==false){
//       $("#showLoginmodal").modal("show");
//     }
//   }
//   Login(loginCheck){
//     if(loginCheck=="register"){
//       this.isLogin=false;
//       this.isRegister=true;
//     }else{
//       this.isLogin=true;
//       this.isRegister=false;
//     }
//   }
//   userLogin(){    
//     this.submitted = true;
//         this.setUserCategoryValidators();
//         if (this.registerForm.invalid) {
//             return;
//         }
//         this.loading = true;        
//         let vUserAuthentication = new UserAuthentication();
//         vUserAuthentication.UserName='onlinehealth@gmail.com';
//         vUserAuthentication.Password='123456';
//         this.authService.getAuthentication(vUserAuthentication).subscribe((data: token)=>{          
//           this.token=data;
//           if(this.token.access_token !==''){
//               this.loading = false;
//               sessionStorage.setItem('access_token',this.token.access_token);
//               //this.router.navigate(["/dashboard"]);
//               this.GetUserDetails(this.registerForm.value.Email);
//           }
//           else{
//               this.loading = false;
//               Swal.fire('Some error.', '', 'error');
//           }         
          
//         },((err:ErrorHandle) => {
//           this.ErrorHandle=err;       
//            if(this.ErrorHandle.statusText=="Bad Request"){
//              Swal.fire('Userid and password are incorrect.', '', 'error');
//            }
//       }));
//           //loginUser
//   }
//   get r() { return this.registerForm.controls; }
//   userRegister(){
//     this.submitted = true;
//         this.setUserCategoryValidators();
//         if (this.registerForm.invalid) {
//             return;
//         }
//         this.loading = true;
//         this.authService.userRegisterForm(this.registerForm.value).subscribe((data)=>{          
//           if(data>0){
//               this.loading = false;
//               Swal.fire('User registration successfully.', '', 'success');
//               this.getAuthentication('onlinehealth@gmail.com','123456')
//               //this.GetUserDetails(this.registerForm.value.Email);
//           }
//           else{
//               this.loading = false;
//               Swal.fire('Some error.', '', 'error');
//           }         
          
//         },((err:ErrorHandle) => {
//           this.ErrorHandle=err;       
//            Swal.fire(this.ErrorHandle.statusText, '', 'error');           
//       })); 
//   }
//   GoToDashboard(){debugger;
    
//     this.authService.geUser(sessionStorage.getItem('email'))
//     .subscribe((data: User[]) =>{ 
//       if(data.length>0){
//         localStorage.setItem('userName',data[0].UserName);
//         let Checkit = data[0].Email + '|' + data[0].password;
//         var encrypted = this.EncrDecr.set('123456$#@$^@1ERF', JSON.stringify(Checkit));
//         // window.location.href = "http://localhost:4200/dashboard?userid="+encrypted;
//         window.open("https://bimaraja.com/dashboard?userid="+encrypted,'_self');
//     }
//     },((err:ErrorHandle) => {
//       this.ErrorHandle=err;    
//       Swal.fire(this.ErrorHandle.statusText, '', 'error');  
//   }));
          
//   }
//   getAuthentication(username,password){
//     let vUserAuthentication = new UserAuthentication();
//         vUserAuthentication.UserName=username;
//         vUserAuthentication.Password=password;
//         this.authService.getAuthentication(vUserAuthentication).subscribe((data: token)=>{          
//           this.token=data;
//           if(this.token.access_token !==''){
//               this.loading = false;
//               sessionStorage.setItem('access_token',this.token.access_token);
//               //this.router.navigate(["/dashboard"]);
//               this.GetUserDetails(username);
//           }
//           else{
//               this.loading = false;
//               Swal.fire('Some error.', '', 'error');
//           }         
          
//         },((err:ErrorHandle) => {
//           this.ErrorHandle=err;       
//            if(this.ErrorHandle.statusText=="Bad Request"){
//              Swal.fire('Userid and password are incorrect.', '', 'error');
//            }
//       }));

//   }
//   GetUserDetails(username){
//     this.authService.geUser(username)
//     .subscribe((data: User[]) =>{ 
//       if(data.length>0){
//       this.isLogedUser=true;  
//       this.globalService.isLogin=true;  
//       this.globalService.userName='Welcome,'+data[0].UserName;  
//       sessionStorage.setItem('UserId',data[0].UserId.toString()); 
//       sessionStorage.setItem('userName',data[0].UserName);
//       sessionStorage.setItem('email',data[0].Email);
//       sessionStorage.setItem('mobile',data[0].Mobile);
//       sessionStorage.setItem('password',data[0].password);
//       if(data[0].UserName !=""){        
//         $("#showLoginmodal").modal("hide");
//        // this.savePremium('proposal');
//       }else{
//         Swal.fire('Userid and password not matched', '', 'error');
//       }
//     }else{
//       Swal.fire('Userid and password not matched', '', 'error');
//     }
//     },((err:ErrorHandle) => {
//       this.ErrorHandle=err;    
//       Swal.fire(this.ErrorHandle.statusText, '', 'error');  
//   }));

//   }
//   setUserCategoryValidators() {
//     const name = 'ONLINEHEALTH';
//     const emailId = 'onlinehealth@gmail.com';
//     const password = '123456';
//     const mobileNo = '9999999999';
//       //  if (this.isLogin === true) {
//       //     mobileNo.setValidators(null);
//       //     name.setValidators(null);

//       //     emailId.setValidators([Validators.required, Validators.email]);
//       //     password.setValidators([Validators.required, Validators.minLength(6)]);
//       //   }
//       //   if (this.isRegister === true) {
//       //     mobileNo.setValidators([Validators.required, Validators.pattern("[0-9]{10}") ]);
//       //     name.setValidators([Validators.required]);
//       //     emailId.setValidators([Validators.required, Validators.email]);
//       //     password.setValidators([Validators.required , Validators.minLength(6)]);
//       //   }
//       //   name.updateValueAndValidity();
//       //   mobileNo.updateValueAndValidity();   
//       //   emailId.updateValueAndValidity();
//       //   password.updateValueAndValidity();   

//   }

  // userLogout(){
  //   sessionStorage.removeItem('FullName');
  //    sessionStorage.removeItem('EmailId');
  //   sessionStorage.removeItem('MobileNo');
  //   // sessionStorage.removeItem('userName');
  //   //   sessionStorage.removeItem('email');
  //   //   sessionStorage.removeItem('mobile'); 
  //   //   sessionStorage.removeItem('UserId');
  //   //   sessionStorage.removeItem('access_token');
  //   //   localStorage.removeItem('UserId');
  //   //   this.globalService.isLogin=false; 
  //   //   this.isLogin=false;
  //     // window.location.reload();
  //     // this.router.navigate(['/health']);
  //     // this.router.navigate(['/login'])
  //     // .then(() => {
  //     //   window.location.reload();
  //     // });
  //     window.location.href="https://bimaraja.com";
  // }

//   rememberMe(isChecked: boolean){
//     if(isChecked) {
//       let emilid=this.registerForm.value.Email;
//       if(emilid !=null){
//         localStorage.setItem('email',emilid);
//       }      
//     }else{
//       localStorage.removeItem('email');
//     }
//   }
// }
