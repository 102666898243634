import { Component, OnInit } from "@angular/core";

import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ReactiveFormsModule,
  FormsModule,
  FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import { HealthservicesService } from "src/app/services/healthservices.service";
import Swal from "sweetalert2";
declare var $: any;
import { CustomerLogin } from "../../Models/Customer";
import { MotorService } from "src/app/services/motor.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  Show: boolean = false;
  // userName:any;
  // password:any;
  user: any = new CustomerLogin();
  submitted: boolean = false;
  verifyotp: boolean = false;
  isMobileOTPVerified: boolean = false;
  showOTPFields = false;
  otpSentTime: number = 0;
  showResendButton: boolean = false;
  countdown: number = 30;
  timerInterval: any;
  displayTime = "";

  Loginform: FormGroup = new FormGroup({
    userName: new FormControl(""),
    password: new FormControl(""),
  });

  constructor(
    private healthMasterService: HealthservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private motorService: MotorService
  ) {}

  ngOnInit() {
    this.Loginform = this.formBuilder.group({
      userName: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  // captureOTP(OTPType: any) {
  //   debugger;
  //   if (OTPType == 'M' && ($("#Mobile").val() == '' || $("#Mobile").val() == undefined)) {
  //     Swal.fire("", "Kindly Enter Mobile Number", "error");
  //     return;
  //   }
  //   this.SaveRiskcareUser();

  //   var val = {
  //     IsActive: true,
  //     UserId: $("#Mobile").val(),
  //     MobileNo: $("#Mobile").val(),
  //     OTPExpiry: "",
  //     EmailId: $("#Email").val(),
  //     Remarks: OTPType,
  //     Status: "",
  //     CreatedBy: "",
  //     CreatedByIP: ""
  //   }
  //   this.motorService.SendOTP(val).subscribe((data: any) => {
  //     debugger;
  //     if (data.Status == "SUCCESS") {
  //       if (OTPType == 'M')
  //         Swal.fire("", "OTP sent to mobile number", "success");

  //       else
  //         Swal.fire("", "OTP sent to Email id", "success");

  //       this.verifyotp = true;
  //       this.showOTPFields = true;
  //     } else if(){

  //     }
  //     else {
  //       if (data.ErrorMessage != "") {
  //         Swal.fire("", data.ErrorMessage, "error");

  //       }
  //       else {
  //         Swal.fire("", "OTP sending failed", "error");

  //       }
  //       this.verifyotp = false;
  //     }

  //   });
  // }

  captureOTP(OTPType: any) {
    debugger;
    if (
      OTPType == "M" &&
      ($("#Mobile").val() == "" || $("#Mobile").val() == undefined)
    ) {
      Swal.fire("", "Please Enter Valid Mobile Number", "error");
      return;
    }
     else if ($("#Mobile").val().length < 10) {
      Swal.fire("", "Mobile Number should be 10 digits", "error");
    } else {
      // this.SaveNewUser();

      var val = {
        IsActive: true,
        UserId: $("#Mobile").val(),
        MobileNo: $("#Mobile").val(),
        OTPExpiry: "",
        EmailId: $("#Email").val(),
        Remarks: OTPType,
        Status: "",
        CreatedBy: "",
        CreatedByIP: "",
      };
      this.motorService.SendOTP(val).subscribe((data: any) => {
        debugger;
        if (data.Status == "SUCCESS") {
          Swal.fire(
            "",
            OTPType == "M" ? "OTP sent to mobile number" : "OTP sent to Email id",
            "success"
          );
  
          this.verifyotp = true;
          this.showOTPFields = true;
          this.otpSentTime = Date.now();
          this.startCountdown();
        } else {
          Swal.fire("", data.ErrorMessage || "OTP sending failed", "error");
          this.verifyotp = false;
        }
      });
    }
  }

  // startCountdown() {
  //   this.countdown = 30;
  //   this.showResendButton = false;
  //   this.timerInterval = setInterval(() => {
  //     this.countdown--;
  //     if (this.countdown <= 0) {
  //       clearInterval(this.timerInterval);
  //       this.showResendButton = true;
  //     }
  //   }, 1000);
  // }

  startCountdown() {
    this.countdown = 600;
    this.showResendButton = false;
    this.timerInterval = setInterval(() => {
      this.countdown--;

      if (this.countdown <= 0) {
        clearInterval(this.timerInterval);
        this.showResendButton = true;
        this.countdown = 0;
      }
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      this.displayTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }, 1000);
  }

  ngOnDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  SaveNewUser() {
    debugger;
    this.motorService
      .SaveNewUser($("#Name").val(), $("#Email").val(), $("#Mobile").val())
      .subscribe((res: any) => {
        if (res == true) {
          let data = res;
        }
      });
  }

  // OTPValidation(OTPType: any) {
  //   debugger;
  //   if (
  //     OTPType == "M" &&
  //     ($("#MOTP").val() == "" || $("#MOTP").val() == undefined)
  //   ) {
  //     Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
  //     return;
  //   }

  //   var val = {
  //     IsActive: true,
  //     UserId: $("#Mobile").val(),
  //     MobileNo: $("#Mobile").val(),
  //     OTPExpiry: "",
  //     EmailId: $("#Email").val(),
  //     Remarks: "",
  //     Status: "",
  //     CreatedBy: "",
  //     CreatedByIP: "",
  //   };
  //   sessionStorage.setItem("MobileNo", $("#Mobile").val());
  //   this.motorService.OTPValidation(val).subscribe((data: any) => {
  //     debugger;

  //     if (OTPType == "M" && $("#MOTP").val() == data[0].OTP) {
  //       Swal.fire("", "Login Sucessfull", "success");
  //       this.isMobileOTPVerified = true;
  //       this.Login();
  //     } else if (OTPType == "M" && $("#MOTP").val() != data[0].OTP) {
  //       Swal.fire("", "Mobile OTP is not correct", "error");
  //     } else {
  //       Swal.fire("", "Please provide correct OTP", "error");
  //     }
  //   });
  // }

  OTPValidation(OTPType: any) {
    debugger;
    if (OTPType === "M" && ($("#MOTP").val() === "" || $("#MOTP").val() === undefined)) {
        Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
        return;
    }

    var val = {
        IsActive: true,
        UserId: $("#Mobile").val(),
        MobileNo: $("#Mobile").val(),
        OTP: $("#MOTP").val(), 
        EmailId: $("#Email").val(),
        Remarks: "",
        Status: "",
        CreatedBy: "",
        CreatedByIP: "",
    };
    
    sessionStorage.setItem("MobileNo", $("#Mobile").val());

    this.motorService.OTPValidation(val).subscribe((data: any) => {
      if (data.success) {
          Swal.fire("", data.message, "success");
          this.isMobileOTPVerified = true;
          this.Login();
      } else {
          Swal.fire("", data.message || "Please provide correct OTP", "error");
      }
  }, error => {
      Swal.fire("", "An unexpected error occurred", "error");
  });
  
}
  get f(): { [key: string]: AbstractControl } {
    return this.Loginform.controls;
  }

  async Login() {
    debugger;
    this.submitted = true;
    var val = {
      userName: this.user.UserName,
      password: this.user.Password,
      flag: "Login",
    };

    if (this.isMobileOTPVerified == true) {
      this.onReset();

      sessionStorage.setItem("isLogin", "true");
      this.router.navigate(["/profile"]);
    }
  }

  ChangePassword() {
    this.Show = !this.Show;
  }

  onlyNumbers($event) {
    const pattern = /[0-9]/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar.toString())) {
      // invalid character, prevent input
      //event.preventDefault();
      return false;
    }
  }

  onReset() {
    this.user.UserName = "";
    this.user.Passsword = "";
  }
}
