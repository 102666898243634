export class Customer
{
    fullName: string="";
    emailId: string ="";
    mobileNo: string ="";
    password: string ="";
    gender: string ="";
    dateOfBirth: string ="";
    state: string ="";
    city: string ="";
    address: string ="";
    addressAlternate: string ="";
    landmark: string ="";
    createdBy: string ="";
    modifyBy: string ="";
    flag: string ="";
    AlternateMobileNo: string = "";
}

export class CustomerLogin
{
    userName: string;
    password: string ;
    flag: string ="";
}