export class premiumModel{
    planId:number;
    planName:string;
    img:string;
    cover:string;
    policyType:string;
    cashlessHospitals:number;
    premiumForMonth:number;
    annualPremium:number;
    ProductId:number;
    productname:string;
    companyName:string;
  Addons: any;
    
}

export class insuredMember{
    id:number;
    memberType:string;
    memberName:string;    
    name:string='';
    lastname:string='';
    sumInsured:string;
    dateOfBirth:string;
    age:number; 
    gender:string=null;
    salutation:string=null;
    occupation:string=null; 
    nomineeRelation:string="0";
    nomineeName:string='';
    weight:number;
    height: number;
    bmi: string;
    maritalStatusCode:string=null;
    nomineeDOB:string=null;
    nomineeOccupation:string=null;
    nomineeGender:string=null;
    //SAtish
    otheroccupation:string=null;
    abhaNo:string=null;
    //SAtish
}

export class proposer{
CustomerName:string;
QuotationId:number;
Mobile:string;
Email:string;
Pincode:string;
City:string;
State:string;
Lob:string;
}
export class quotePlan{
    ProductId:number;
    PlanID: number;
    Description:string;
}
export class premiumData{
    SessionId: string;
    PlanType: string;
    PolicyType: string;
    FamilyComposition:string;
    ProductName: string;
    PolicyStartDate: string;
    PolicyEndDate: string;
    ProductImage: string;
    CurrentDate:string;
    SumInsured: number;
    CompanyId:number;
    ProductId:number;
    Duration: number;
    TotalMember:number;
    PlanTypeId: number;
    BasicPremium: number;
    CostPerDay: number;
    Premium: number;
    AddOnPremium: number;
    TotalDiscount: number;
    NetPremium: number;
    DiscountPercent: number;
    ServiceTax: number;
    ServiceTaxAmount: number;
    FinalPremium: number;
    AddonApplicable: boolean;
    CompanyName:string ;
    PlanCode: string;
    TotalAdult: number;
    TotalChildren: 0;
    AgeOfEldestMember: number;
    GSTToState: string;
    DateOfBirth: string;
    Message: string;
    QuotionId:number;
    MatchedFeature: number;
    TotalFeature: number;
    HospitalCnt: number;
    QuotationFeatureList: any;
    ToatalSumInsured:number;
}
export class DigitHealthQuestion{
    questionCode:string;
    answerType:string;
    isApplicable:boolean;
    detailAnswer:string;
}
export class CovidQuestions{
    RPCVD: string;
    RPCV1: string;
    RPCV2: string;
    RPCV3:string;
    RPCV4: string;
    RPCV5: string;
    RPCV6: string;
    RPVCN: string;
    RPVC1:string;
    RPVC2: string;
    RPVC3:string;
    RPVC4:string;
    RPVC5: string;
    RPVC6:string;
    RPVC7: string;
    RCOVI: string;
    RPCVI: string;
}